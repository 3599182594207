* {
	box-sizing: border-box;
}
html {
	-webkit-text-size-adjust: 100%;
}
body {
	background-image: url(./images/siteBG.gif); 
	margin: auto;
	padding: 0;
	font-size: 20px;
	font-family: "Helvetica Neue LT W06_45 Light", "Helvetica Neue LT W06_55 Roman", sans-serif;
	color: #333;
}
p {
	font-size: 1rem;
	line-height: 1.4em;
}
hr {
	margin: 0px;
}
h1 {
	font-size: 2.5rem;  
	letter-spacing: -0.04em;
	margin:0px;
}
h2 {
	font-size: 1.5rem;    
	color: #666;
}
h2 {
	font-size: 1.5rem;    
	color: #000; 
	margin:0px;
}
.noselect {
	-webkit-touch-callout: none;
	  -webkit-user-select: none;
	   -khtml-user-select: none;
		 -moz-user-select: none;
		  -ms-user-select: none;
			  user-select: none;
} 

//@import "~@progress/kendo-theme-default/scss/all";


