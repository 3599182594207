
.trialSummaryPageContainer{
    .headerContainer{
        .headerTitle {
            display: flex;
            background: #454545;
            color: #fff;
            font-size: 22px;
            line-height: 22px;
            padding: 19px;
        }
        .headerDetails {
            display: flex;
            flex-direction: column;
            background: #e9e7e7;
            padding: 27px 20px;
            font-size: 16px;
            line-height: 26px;
            .row {
                display: flex;
            }
            .cell {
                display: flex;
                flex: auto;
            }
            .cell span{
                white-space: pre;
                font-weight: bold;
            }
            .cell.one {
                width: 40%;
            }
            .cell.two {
                width: 60%;  
            }
        }
    }

    .comparisonsContainer{
        padding: 25px;
        background: #fff;
    }
}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 767px) {
    .trialSummaryPageContainer{
        .headerContainer{
            .title {
                display: flex;
                background: #454545;
                color: #fff;
                font-size: 18px;
                line-height: 25px;
                padding: 18px;
            }
            .headerDetails {
                display: flex;
                flex-direction: column;
                background: #e9e7e7;
                padding: 10px 20px;
                font-size: 16px;
                line-height: 26px;
                .row {
                    display: flex;
                    flex-direction: column;
                }
                .row {
                    display: flex;
                    flex-direction: column;
                }
                .cell {
                    display: flex;
                    flex: auto;
                }
                .cell span{
                    white-space: pre;
                    font-weight: bold;
                }
                .cell.one {
                    width: 100%;
                }
                .cell.two {
                    width: 100%;  
                }
            }
        }

        .comparisonsContainer{
            padding: 20px;
            background: #fff;
        }
    }
}