.accordionContainer {
	margin: 10px 0px;
}

.contentAcc {
	border-top: 1px solid #65ac1e;
}

.contentAccparent {
	border-bottom: 1px solid #65ac1e;
	padding: 10px;
}
.contentTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 15px;
	margin: -12px 0px 0px 0px;
	cursor: pointer;
}

.contentTitle h3 {
	color: #65ac1e;
	font-size: 20px;
	font-weight: normal;
}

.contentTitle .arrow {
}

.arrow .fa {
	color: #65ac1e;
}

.contentText {
	padding: 0px;
	margin: -10px 0px 0px 0px;
}

.contentText p {
	font-size: 16px;
}

.contentText ul {
	font-size: 16px;
	margin: 0px 10px;
}
.contentText ol {
	font-size: 16px;
	margin: 0px 10px;
}
.contentText li {
	list-style-type: disc;
	margin: 5px 10px;
	font-weight: normal;
	color: initial;
	padding-left: 0;
	line-height: initial;
	display: list-item;
}
