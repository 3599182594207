*,*:before, *:after {box-sizing: border-box;}

.panel-wrap {
    position: absolute;
    top: -27px;
    bottom: 0;
    right: 0px;
    width: 0em;
    height: 400px;
    overflow: hidden;
    /* transform: translateX(100%); */
    transition: .3s ease-out;
    z-index: 101;
  }
  .panel {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    color: #000;
    overflow: auto;
  }

  .panel p{
    color: #000;
    font-size: 16px;
    padding: 10px 15px;
   }

  .panel .helpHeader{
    background: #65ac1e33;
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
    align-items: center;
  }

  .helpHeader h3{
   color: #65ac1e;
   text-transform: uppercase;
  }

  .helpHeader label{
    color: #65ac1e;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
   }

  .clicker{
    /* padding: 10px 12px;
    color: #fff;
    background: #65ac1e;
    text-transform: uppercase;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    font-weight: 600;
    font-size: 14px; */

    font-size: 14px;
		font-weight: bold;
		text-align: center;
		color: #65ac1e;
		background-color: #fff;
		border: 1px solid #65ac1e;
		cursor: pointer;
		padding: 10px 15px;
		width: 150px;
		margin-left: 10px;
  }
  
  .helpLabel {
    padding-top: 5px;
  }

  .closeBtn {
   cursor: pointer;
  }
  
  [type="checkbox"]:checked ~ .panel-wrap {
    /* transform: translateX(0%);  */
    width: 30em;
  }
  
  [type="checkbox"] {
    font-size: 1em;
    display:none;
  }
  
  body {
	margin: auto;
	padding: 0;
}


@media only screen and (max-width: 1330px) {
  
  .panel-wrap {
      position: fixed;
      top: 135px;
      bottom: 0;
      right: 0;
      width: 30em;
      transform: translateX(100%);
      transition: .3s ease-out; 
  }
  
  [type="checkbox"]:checked ~ .panel-wrap {
    transform: translateX(0%); 
    width: 30em;
    top: 135px;
  }
  
  }

@media only screen and (max-width: 1024px) {
  
  .panel-wrap {
      position: fixed;
      top: 165px;
      bottom: 0;
      right: 0;
      width: 30em;
      transform: translateX(100%);
      transition: .3s ease-out; 
  }
  
  [type="checkbox"]:checked ~ .panel-wrap {
    transform: translateX(0%); 
    width: 30em;
    top: 165px;
  }
  
  }

@media only screen and (max-width: 767px) {
  .panel-wrap {
    height: 0;
    overflow: hidden;
    /* width: 26em !important; */
    width: 100% !important;
    margin-top: -10px;
    -webkit-transition: height .3s ease;
  }
  
  [type="checkbox"]:checked ~ .panel-wrap {
    transform: translateX(0%); 
    height: 400px;
    top: 135px;
  }
}

@media only screen and (max-width: 400px) {
  
  .panel-wrap {
    height: 0;
    overflow: hidden;
    /* width: 24em !important; */
    width: 100% !important;
    margin-top: -10px;
    -webkit-transition: height .3s ease;
  }
  
  [type="checkbox"]:checked ~ .panel-wrap {
    transform: translateX(0%); 
    height: 400px;
    top: 135px;
  }
}

@media only screen and (max-width: 360px) {
  
  .panel-wrap {
    height: 0;
    overflow: hidden;
    /* width: 23em !important; */
    margin-top: -25px;
    -webkit-transition: height .3s ease;
  }
  
  [type="checkbox"]:checked ~ .panel-wrap {
    transform: translateX(0%); 
    height: 400px;
    top: 135px;
  }
}

@media only screen and (max-width: 320px) {
  
  .panel-wrap {
    height: 0;
    overflow: hidden;
    /* width: 20em !important; */
    margin-top: -25px;
    -webkit-transition: height .3s ease;
  }
  
  [type="checkbox"]:checked ~ .panel-wrap {
    transform: translateX(0%); 
    height: 400px;
    top: 135px;
  }
}