.homeMapContainer {
	display: flex;
	flex-direction: row;
	background: #fff;
	padding: 2px;
	position: relative;
	.mapContainer {
		height: calc(100vh - 135px);
		width: calc(100% - 500px);
		background: #fff;
		margin-bottom: 30px;
		border: 1px solid #65ac1e;
		margin-right: 5px;
		div:first-child {
		}
		.mapLegend {
			position: absolute;
			z-index: 2;
			background: none;
			// bottom: 60px;
			bottom: 8%;
			margin-left: 10px;
			padding: 10px;
			button {
				display: flex;
				flex-direction: row;
				height: 30px;
				background-color: rgba(255, 255, 255, 0.9);
				text {
					font-weight: bold;
					padding: 5px;
				}
				.triangle {
					font-size: 10px;
				}
			}
			.mapLegendTitle {
				margin: 0;
				font-size: 17px;
				padding: 6px;
				span {
					float: right;
				}
			}
			.mapKeys {
				padding: 5px;
				background: rgba(255, 255, 255, 0.9);
				list-style: none;
				margin: 0;
				li {
					margin: 2px 0;
				}
				.mapKey {
					font-size: 0.7em;
					display: inline-block;
					vertical-align: text-top;
				}
			}
		}
	}
	.mapMenu {
		display: flex;
		flex-direction: column;
		height: calc(100vh - 125px);
		overflow: auto;
		background: #fff;
		border: 1px solid #65ac1e;
	}
	.hideOnMobile {
		display: flex;
		width: 100%;
	}
	.showOnMobile {
		display: none;
	}
}

@media only screen and (max-width: 1024px) {
	.homeMapContainer {
		display: unset;
		.mapContainer {
			height: calc(100vh - 165px);
			width: 100%;
			.mapLegend {
				padding: 0;
				background: none;
				bottom:30px;
				position: fixed;
				.mapKeys {
					padding: 5px;
					background: rgba(255, 255, 255, 0.9);
				}
				button {
					height: 30px;
					background: rgba(255, 255, 255, 0.9);
				}
			}
		}
		.mapMenu {
			width: 100%;
			height: calc(100vh - 165px);
		}
		.hideOnMobile {
			display: none;
		}
		.showOnMobile {
			display: block;
			z-index: 0;
		}
	}
}

@media only screen and (max-width: 1024px) {
	.homeMapContainer {
		padding: 0px;
	}
}

@media only screen and (max-width: 1024px) {
	.homeMapContainer {
		padding: 0px;
		.mapContainer {
			border: none;
		}
	}
}

@media only screen and (max-width: 767px) {
	.homeMapContainer {
		padding: 0px;
		.mapContainer {
			padding-top: 0px;
		}
	}
}

@media only screen and (min-width: 1025px) {
	.searchBarParent{
		display: flex;
		background-color: white;
		width: 100%;
		.conditionalShow{
			width: 100%;
			.searchBarContainer{
				width: calc(100% - 500px);
				.searchBar{
					min-width: 500px;
					max-width: 800px;
					margin: 0 auto;
				}
			}
		}
	}
}

.searchBarContainer {
	padding: 5px 20px;
	background: white;
	.searchBar {
		border: 3px solid #dddddd;
		input[type='text'] {
			padding: 7px;
			font-size: 16px;
			width: calc(100% - 50px);
			border: none;
		}
		button {
			float: right;
			width: 45px;
			height: 25px;
			margin-top: 5px;
			padding: 0px 5px;
			font-size: 17px;
			border: none;
			background: #fff;
			border-left: 1px solid #dddddd;
			cursor: pointer;
		}
		button:focus,
		input:focus {
			outline: none;
		}
		img {
			width: 15px;
		}
		li {
			cursor: pointer;
		}
	}

	.autocomplete-dropdown-container {
		font-size: 16px;
		color: #000;
		line-height: 22px;
		margin-left: 5px;
	}
	
}
