.backtotop{
	cursor: pointer;
    position: fixed;
    bottom: 50px;
    right: 0;
    width: 43px;
    height: 50px;
    background-color: rgba(85, 85, 85, .5);
    text-align: center;
    padding: 2px 10px;
	z-index: 1000;	
	display:none;
span {
	color: white;
	font-size: 12px;
	 }
}

.backtoptoptransition{
    display:block;
    transition: all 0.5s ease-in-out;
}