.app-header {
	height: 80px;
	position: relative;
	background-color: #64ab1f;
}
/* .app-header .app-logo {
	height: 80px;
	background-image: url(../../images/common/BASF_Logo.svg);
	background-repeat: no-repeat;
} */
.app-header img {
    height: 78px;
}
.bannerLink {
    height: 100%;
    width: 450px;
    z-index: 9998;
	position: absolute; 
	cursor: pointer;
    border: none;
    background-color: rgba(0, 0, 0, 0);
}
.bannerLink:focus {
	outline: none;
}

@media only screen and (max-width: 450px) {
    .app-header img {
     height: 66px;
    margin-top: -16px;
    margin-left: -10px;
}
    
.bannerLink {
    height: 100%;
    width: 315px;
    z-index: 9998;
	position: absolute; 
	cursor: pointer;
    border: none;
	background-color: rgba(0, 0, 0, 0);
	margin-top: 9px;
}
}

@media only screen and (max-width: 360px) {
    .app-header {
        height: 65px;
        position: relative;
        background-color: #64ab1f;
    }
    .app-header img {
        height: 63px;
        margin-top: -14px;
        margin-left: -6px;
    }
    .app-header .app-logo {
        height: 65px;
        background-image: url(../../images/BASF_Logo.svg);
        background-repeat: no-repeat;
    }
    .bannerLink {
        height: 100%;
        width: 280px;
        z-index: 9998;
        position: absolute; 
        cursor: pointer;
        border: none;
        background-color: rgba(0, 0, 0, 0);
        margin-top: 9px;
    }
}

@media only screen and (max-width: 360px) {
    .app-header img {
        height: 55px;
        width:100%;
        margin-top: -14px;
        margin-left: -8px;
    }

}