.linearGaugeContainer {
    font-size: 15px;
    margin-top:12px;

    .linearGaugeText {
        float: left;
        width: 16%;
        transition: width 500ms ease 0s;
        height: 50px;
    }

    .linearGaugeContent {
        float: left;
        width: 83%;
        transition: width 500ms ease 0s;
        height: 50px;

        .gauge {
            display: table;
            width: 100%;

            .top {
                display: table-row;

                .line {
                    display: table-cell;
                    width: 10%;
                    height: 10px;
                }

                .line:not(:first-child) {
                    border-left: 2px solid #d3d2d2;
                }
            }

            .bottom {
                display: table-row;

                .line {
                    display: table-cell;
                    width: 10%;
                    border-top: 2px solid #d3d2d2;
                    height: 10px;
                }

                .line:not(:first-child) {
                    border-left: 2px solid #d3d2d2;
                }
            }
        }

        .nums {
            position: relative;

            .num {
                position: absolute;
                top: .5em;
                color: #929292;
            }
        }
    }
}

@media only screen and (max-width: 1330px) {
    .linearGaugeContainer {
        .linearGaugeText {
            width: 17%;
        }

        .linearGaugeContent {
            width: 82%;
        }
    }
}

@media only screen and (max-width: 1153px) {
    .linearGaugeContainer {
        .linearGaugeText {
            width: 18%;
        }

        .linearGaugeContent {
            width: 81%;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .linearGaugeContainer {
        font-size: 14.5px;

        .linearGaugeText {
            width: 20%;
            line-height: 37px;
        }

        .linearGaugeContent {
            width: 79%;

            .gauge {
                .top {
                    .line {
                        height: 8.5px;
                    }

                    .line:not(:first-child) {
                        border-left: 1.8px solid #d3d2d2;
                    }
                }

                .bottom {
                    .line {
                        height: 8.5px;
                        border-top: 1.8px solid #d3d2d2;
                    }

                    .line:not(:first-child) {
                        border-left: 1.8px solid #d3d2d2;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 896px) {
    .linearGaugeContainer {
        .linearGaugeText {
            width: 22%;
        }

        .linearGaugeContent {
            width: 77%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .linearGaugeContainer {
        font-size: 14px;

        .linearGaugeText {
            width: 25%;
            height: 34px;
            line-height: 34px;
        }

        .linearGaugeContent {
            width: 74%;

            .gauge {
                .top {
                    .line {
                        height: 7px;
                    }

                    .line:not(:first-child) {
                        border-left: 1.6px solid #d3d2d2;
                    }
                }

                .bottom {
                    .line {
                        height: 7px;
                        border-top: 1.6px solid #d3d2d2;
                    }

                    .line:not(:first-child) {
                        border-left: 1.6px solid #d3d2d2;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 672px) {
    .linearGaugeContainer {
        .linearGaugeText {
            width: 30%;
        }

        .linearGaugeContent {
            width: 64%;
        }
    }
}

@media only screen and (max-width: 576px) {
    .linearGaugeContainer {
        font-size: 13.5px;

        .linearGaugeText {
            width: 35%;
        }

        .linearGaugeContent {
            width: 64%;
        }
    }
}

@media only screen and (max-width : 480px) {
    .linearGaugeContainer {
        margin-bottom: 2%;
        font-size: 13px;

        .linearGaugeText {
            width: 40%;
            height: 30px;
            line-height: 30px;
        }

        .linearGaugeContent {
            width: 59%;

            .gauge {
                .top {
                    .line {
                        height: 5px;
                    }

                    .line:not(:first-child) {
                        border-left: 1.4px solid #d3d2d2;
                    }
                }

                .bottom {
                    .line {
                        height: 5px;
                        border-top: 1.4px solid #d3d2d2;
                    }

                    .line:not(:first-child) {
                        border-left: 1.4px solid #d3d2d2;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width : 420px) {
    .linearGaugeContainer {
        .linearGaugeText {
            width: 45%;
        }

        .linearGaugeContent {
            width: 54%;
        }
    }
}

@media only screen and (max-width : 360px) {
    .linearGaugeContainer {
        .linearGaugeText {
            width: 50%;
        }

        .linearGaugeContent {
            width: 49%;
        }
    }
}