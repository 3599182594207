.talkContainer {
    display: flex;
    background: #e9e7e7;

    .full {
        width: 100%; 
        display: flex;
        flex: 100%;  
        position: relative; 
        -ms-flex: 100%; 
    }
    .left{
        width: 50%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
            height: 75%;
        } 
        p {
            margin: 5px;
        }
        button{
                width: 250px;
                height: 45px;
                font-size: 15px;
                font-weight: bold;
                color: #fff;
                background: #5b9f05;
                border: none;
                line-height: 45px;
                cursor: pointer;
        }
    }
    .half {
        display: flex;
        flex-direction: column;
        padding: 30px;
        color:#fff;
        width: 50%;
        img {
            width: 50px;
            margin-right: 20px; 
            cursor: pointer;
        } 
        .talkButton { 
            background: #fff;
            line-height: 40px;
            color: black;
            text-align: center;
            cursor: pointer;  
            margin-bottom: 120px;
        }
        a {
            width: 150px;
            height: 40px;
            text-decoration: none; 
        }
        h2, p{
          color:#fff;  
        }
        .questions{
            color: #000;
        }
    }
    
} 

@media only screen and (max-width:767px) { 
    .talkContainer {
        flex-direction: column;
    .left {
        display: flex;
        flex: 100%;
        flex-direction: column;
        padding: 30px;
        width: 100%;
    }
    .half {
        display: flex;
        flex: 100%;
        flex-direction: column;
        padding: 30px;
        width: 100%;
    }
}
}