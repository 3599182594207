.individualTrialSummaryBody {
    display: flex;
    cursor: pointer;
    // padding: 10px 30px; 
    // padding: 20px 20px 20px 20px;
    .tsBoxTopBorder {
        //border-top: 6px solid #0061a1;
    }
    .tsBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: #fff;
        //box-shadow: 0px 0px 10px 0px grey;
        .tsBoxText{
            padding: 10px 22px;
           //background:#dbf0fe;
            .subTSBoxText1 {
                font-size: 18px;  
                font-weight: bold;
            }
            .subTSBoxText {
                font-size: 16px; 
                text-transform: capitalize; 
            }

        }
        .comparisonContent{
            padding: 10px 20px;
            .chartComparisonContainer{
            .rowTitle {
                margin-top: 20px;
                font-size: 16px;
                display: inline-block;
                }
            }
        }
        .tsBoxContainer {
            display: flex;
        }
        .tsTextContainer {
            flex: 3;
        }
        .tsButtonContainer {
            display: flex;
            justify-content: flex-end;
            padding: 0px 30px;
            margin: 0px 0px;
            flex: 1 1;
            align-items: center;
            box-sizing: border-box;
            .tsBoxButton {
                font-size: 14px;
                font-weight: bold;
                text-align: center;
                color: #fff;
                background-color: #2e2e2e;
                cursor: pointer;
                padding: 10px 10px;
                margin: 0px -40px;
                width: 125px;
            }
        }
 
        /* BEGINNING OF CHART STYLES */
        .chartHeadContainer {
            display: flex;
            flex-direction: row;
            font-size: 22px;
            padding: 2px 0px;
            margin-top:10px;
            .chartHead { 
                float: left;
                font-size:18px;
                font-weight: bold;
            }
            .chartHead-bracket-text {
                margin-top:7px;
                float: right;
                margin-left:5px;
                font-size:11px;
            }
            
        }
        .chartHeaderTitle {
            display: flex;
            font-size: 16px;
            margin: 0px 0px;
            .year { 
                flex: 1;
                margin-left: 10px;
                font-size:16px;
                border-left: 1px solid #333;
                padding-left: 7px;
            }
        }

        /* MAIN CHART */
        // .chartContainer {
        //     display: flex;
        //     flex-direction: column;
        //     padding: 10px 30px;
        //     font-size: 15px;
        //     .chartRow{
        //         display: flex;
        //         padding: 5px 0px;
        //         .rowTitle {
        //             width: 100%;
        //             display: flex; 
        //         }
        //         .rowBar {
        //             width: 330px; 
        //             background: #f2f2f2;
        //             text-align: center;
        //         }
        //             .itemBar {
        //                 text-align: center; 
        //                 height: 20px;
        //                 line-height: 20px;
        //                 color:#fff;
        //                 margin-bottom:5px;
        //             }
        //     }
        // }

        .weatherFlag{
            float: right;
            color: #fff;
            font-size: 12px;
            border: 2px solid white;
            background: red;
            padding: 6px;
            border-radius: 50px;
        }

        .chartItemContainer {
            .barContainer{
                margin-bottom: -4px;
            .itemText {
                float: left;
                width: 40%;
                font-size: 14.7px;
                font-weight: bold;
                height: 32px;
                line-height: 18px;
                align-items: center;
                display: flex;
                margin-bottom: 10px;
            }
            .itemBarYield p{
                font-size: 14.7px;
                font-weight: bold;
                height: 32px;
                line-height: 18px;
                align-items: center;
                display: flex;
                margin-bottom: 10px;
                padding: 10px;
            }
            .itemBarContainer {
                float: left;
                width: 44%;
                height: 10px;
                line-height: 32px;
                margin-bottom: 10px;
                margin-right: 0%;
                margin-left: 2%;
                background: #efefef;
                margin-top: 10px;
                .itemBar {
                    text-align: center;
                    height: 10px;
                    line-height: 32px;
                    color: #fff;
                    font-size: 15px;
                    background: #0061a1;
                }
            }
        }
    }
    

        @media only screen and (max-width: 767px) { 
            .chartContainer {
                .chartRow{
                    .rowTitle {
                    }
                    .rowBar {
                        width: 264px; 
                        background: #f2f2f2;
                        text-align: center;
                    }
                }
            }

            .chartItemContainer {
                .barContainer{
                    margin-top:15px;
                .itemText {
                    float: left;
                    width: 30%;
                    font-size: 12.5px;
                    font-weight: bold;
                    height: 20px;
                    line-height: 15px;
                    margin-left: 1%;
                    margin-bottom: 14px;
                    align-items: center;
                    display: flex;
                    padding-top: 20px;
                }
                .itemBarYield p {
                    font-size: 14.7px;
                    font-weight: bold;
                    height: 32px;
                    line-height: 18px;
                    align-items: center;
                    display: flex;
                    margin-bottom: 10px;
                    padding: 10px;
                    padding-top: 20px;
                }
                .itemBarContainer {
                    float: left;
                    width: 49%;
                    height: 20px;
                    line-height: 20px;
                    margin-bottom: 14px;
                    margin-right: 0%;
                    background: #efefef;
                    .itemBar {
                        text-align: center;
                        height: 20px;
                        line-height: 20px;
                        color: #fff;
                        font-size: 13px;
                        background: #0061a1;
                    }
                }
            }
        }

            }

            @media only screen and (max-width: 407px) { 
                .chartContainer {
                    .chartRow{
                        .rowTitle {
                        }
                        .rowBar {
                            width: 212px; 
                            background: #f2f2f2;
                            text-align: center;
                        }
                    }
                }

                .chartItemContainer {
                    .barContainer{
                        margin-top:15px;
                        margin-bottom: 0px;
                        .itemText {
                            float: left;
                            width: 35%;
                            font-size: 12px;
                            font-weight: bold;
                            margin-left: 1%;
                            margin-bottom: 14px;
                            align-items: center;
                            display: flex;
                            padding-top: 20px;
                        }
                        .itemBarYield p {
                            font-size: 14.7px;
                            font-weight: bold;
                            height: 30px;
                            line-height: 18px;
                            align-items: center;
                            display: flex;
                            margin-bottom: 10px;
                            padding: 10px;
                            padding-top: 20px;
                        }
                        .itemBarContainer {
                            float: left;
                            width: 49%;
                            height: 20px;
                            line-height: 20px;
                            margin-bottom: 14px;
                            margin-right: 0%;
                            background: #efefef;
                            .itemBar {
                                text-align: center;
                                height: 20px;
                                line-height: 20px;
                                color: #fff;
                                font-size: 13px;
                                background: #0061a1;
                            }
                    }
                }
            }
            }
        }
    }


