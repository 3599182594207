.tabs {
	z-index: -1;
	.tab-list {
		display: flex;
		padding: 15px;
		font-size: 15px;
		// height: 62px;
		background: #ffffff;
		// margin-top: 2%;
		// padding: 0% 20%;

		.tab-list-item {
			display: flex;
			flex: 33.333% !important;
			justify-content: center;
			flex-direction: column;
			// align-self: flex-end;
			// height: 40px;
			// margin: 0 2%;
			padding: 0.5rem 0.75rem;
			text-align: center;
			// font-size: 16px;
			font-weight: bold;
			background: #d3d2d2;
			color: #000;
			list-style: none;
			//z-index: 0;
			transition: 0.3s;
			cursor: pointer;
			// box-shadow: 0 0 0 0 rgba(0,0,0,.2);
			// box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
		}
		.tab-list-active {
			background: #929292;
			color: #fff;
			border-width: 1px 1px 0 1px;
		}
	}
	.tab-content {
		z-index: -1;
	}
}

@media only screen and (max-width: 767px) {
	.tabs {
		position: relative;
		z-index: 1;
	}
}
