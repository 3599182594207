.aboutDSTContainer {
    .aboutDSTTopBar {
        width: 100%;
        background: #303030;
        color: #fff;
        padding: 30px;
    }

    .aboutDSTTopBar-agro {
        width: 100%;
        background: #303030;
        color: #fff;
        .aboutDSTTopBar-agro2{
            width: 100%;
            padding: 30px;
            .aboutDSTTopBar-agro2 h2,
            .aboutDSTTopBar-agro2 p {
            width: 65%;
            margin: auto;
            color: #fff;
            text-align: left;
        }
        }
        .aboutDSTTopBar-agro1{
            width: 100%;
            background: #fff;
            color: #000;
            padding: 30px 30px 55px 30px;
            .aboutDSTTopBar-agrocontent{
            width: 65%;
            margin: auto;
            color: #000;
            text-align: left;
        }
        }
        img{
            float: right;
            position: relative;
            top: -435px;
            margin-bottom: -400px;
            right: 66px;
            box-shadow: 7px 7px rgba(0, 0, 0, 0.3);
        }
    }

    .aboutAPTTopBar {
        width: 100%;
        background: #464545;
        color: #fff;
        padding: 30px;
    }
    .aboutDSTTopBar h2,
    .aboutDSTTopBar p {
        max-width: 100%;
        margin: auto;
        color: #fff;
        text-align: left;
    }
    .aboutAPTTopBar h2{
        color: #fff;
    }
    .aboutDSTTopBar h2 {
        margin-bottom: 20px;
    }  
    .full {
        width: 100%; 
        display: flex;
        flex: 100%;  
        position: relative; 
        -ms-flex: 100%; 
    }  
    .half,
    .half-image {
        display: flex;
        flex: 100%;
        flex-direction: column;
        background-color: #fff;     
        -ms-flex: 100%; 
    } 
    .half-image {
        padding: 0px;
    } 
    .content{
        padding: 30px;
    }
    .contentAgro1{
        padding: 30px;
        position: absolute;
        top:33%;
        width: 50%;
    }
    .contentAgro2{
        padding: 30px;
        position: absolute;
        top:4%;
        width: 50%;
    }
    .para2 h2{
        color:#fff;
    }
    .para2{
        color:#fff;
    }
    .half1,
    .half-image {
        display: flex;
        flex: 100%;
        flex-direction: column;
        background-color: #686767;     
        -ms-flex: 100%; 
    } 
    .half1-agro,
    .half-image-agro {
        display: flex;
        flex: 100%;
        flex-direction: column;
        background-color: #65ac13;     
        -ms-flex: 100%; 
    } 
    .half1-apt,
    .half-image {
        display: flex;
        flex: 100%;
        flex-direction: column;
        background-color: #ebdcc7;     
        -ms-flex: 100%; 
    } 
    .full img,
    .half img {
        width: 100%;
        min-height: 100%;
    }
    .highlightsButton { 
        background: #65ac1e;
        line-height: 40px;
        color:#fff;
        text-align: center;
        cursor: pointer;  
    }
    .talkContainer{
        .half{
            background-color: #e9e7e7;
        }
        .left{
            display: none;
        }
    }
}


@media only screen and (max-width: 1024px) {
    .aboutDSTContainer {
        .half,
        .half-image {
            width: 100%; 
            height:90%;
            -ms-flex: 100%; 
            flex: 100%;
        }
        .full {
            display: unset;
        }
        .para2 h2{
            color:#000;
        }
        .para2{
            color:#000;
        }
    }
}

.pdfHome{
    display:none
}

.k-pdf-export {
    .pdfHome{
        display:block
    }
    .app-header {
        height: 80px;
        position: relative;
        background-color: #64ab1f;
    }
    .app-header img {
        height: 78px;
    }
    .bannerLink {
        height: 100%;
        width: 450px;
        z-index: 9998;
        position: absolute; 
        cursor: pointer;
        border: none;
        background-color: rgba(0, 0, 0, 0);
    }
    .bannerLink:focus {
        outline: none;
   }
    .aboutDSTContainer {
        .aboutDSTTopBar {
            width: 100%;
            background: #303030;
            color: #fff;
            padding: 30px;
        }
        .p{
            font-size: 16px;
        }
    }

   
}