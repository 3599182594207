.crContainer {
    .crTopBar {
        width: 100%;
        background: #fff;
        color: #000;
        padding: 30px;
    }

    .crTopBar p{
        width: 100%;
        background: #fff;
        color: #000;
    }

    .crTopBar h2 {
        font-size: 1.3rem;
        color: #000;
        margin: 0px 0px -10px 0px;
    }

    .crTopBar h2 span{
        font-size: 16px;
        color: #000;
        margin: 0px;
        font-weight: normal;
    }

    .crTopBar .small1{
        font-size: 12px;
        color: #000;
        margin: 0px;
    }

    .crTopBar .button1{
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #0061a6;
        background-color: #e9f0f6;
        padding: 8px 18px;
        margin-top: 10px; 
        border: 2px solid #0061a6;
        text-transform: uppercase;
    }

    .crTopBar .button2{
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        color:#fff ;
        background-color: #0061a6;
        border:1px solid #0061a6;
        cursor: pointer;
        padding: 6px 15px;
        margin-top: -5px;
    }

    .crTopBar .tableFixHead table {
        border-collapse: collapse;
        width: 100%;
        white-space: nowrap;
        font-size: 16px;
        margin-bottom: 5px;
    }
    .tableFixHead th,
    .tableFixHead td {
        /* border:1px solid cyan; */
        padding: 8px 16px;
        text-align: center;
        border-bottom: 1px solid #0061a6;
    }
    td:first-child,
    th:first-child {
        position: sticky;
        left: 0;
        z-index: 3;
        text-align: left;
        font-weight: 600;
    }
    .tableFixHead th {
        position: sticky;
        top: 0;
        background:#005ca5;
        text-align: center;
        color: #fff;
        z-index: 2
    }
    th:first-child {
        z-index: 3;
        text-align: left;
    }
  }

tr:nth-child(even) {
    background-color: #fff;
  }
tr:nth-child(odd) {
   background-color: #edf2f8;
  }

  .new{
    color: #ba621a;
  }

  .soldout{
      color: #a6a2a3;
  }

  .crrowFlex {
    display:flex; 
    flex-direction:row;
  }

  .ranking-tooltip{
    float: right;
    text-decoration: underline;
    color: #65AC1E;
    font-size: 12px;
    position: relative;
    z-index:2;
  }

  .ranking-tooltip-text{
    color: black;
    position: absolute;
    background-color: #edf2f8;
    border: 1px solid black;
    border-radius: 2px;
    bottom: 100%;
    right:5px;
    width:275px;
    padding: 5px 8px 5px 8px;
 }

 .ranking-tooltip-text:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 75%;
    margin-left: 10px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
 }

.table-redtext {
  color: red;
}


@media only screen and (max-width: 767px) {
    .crContainer {
        .crTopBar .tableFixHead {
            overflow: auto;
            height: auto;
            width: 100%;
            overflow-x: scroll;
        }
        .tableFixHead table {
            border-collapse: collapse;
            width: 100%;
            white-space: nowrap;
        }
        .tableFixHead th,
        .tableFixHead td {
            /* border:1px solid cyan; */
            padding: 8px 16px;
            text-align: center;
            border-bottom: 1px solid #0061a6;
        }
        td:first-child,
        th:first-child {
            position: sticky;
            left: -1;
            z-index: 3;
            text-align: left;
            background-color:#edf2f8;
            white-space: normal;
            border-bottom: 1px solid #0061a6;
        }
        .tableFixHead th {
            position: sticky;
            top: 0;
            background:#005ca5;
            text-align: center;
            color: #fff;
            z-index: 2
        }
        th:first-child {
            z-index: 3;
            text-align: left;
        } 
      }
    
    tr:nth-child(even) {
        background-color: #fff;
      }
    tr:nth-child(odd) {
       background-color: #edf2f8;
      }
    }


    .flex-grow.flex {
        display: flex;
        padding: 15px;
        font-size: 15px;
        background: #ffffff;
    }

    .flex-1, .flex-2 {
        display: flex;
        flex: 24.333% 1 !important;
        justify-content: center;
        flex-direction: column;
        padding: 0.5rem 0.75rem;
        text-align: center;
        font-weight: bold;
        list-style: none;
        transition: 0.3s;
        border: 1px solid #005ca5;
        cursor: pointer;
    }

    .flex-1 {
        background-color: #005ca5;
        color: white;
    }

    .season {
        display: flex;
        flex: 16.333% 1 !important;
        justify-content: center;
        flex-direction: column;
        padding: 0.5rem 0.75rem;
        text-align: center;
        font-weight: bold !important;
        list-style: none;
        transition: 0.3s;
        color:#005ca5;
        font-size: 20px !important;
    }

    @media only screen and (max-width: 420px) {
        .flex-grow.flex {
            display: flex;
            padding: 15px 0px;
            font-size: 12px;
            background: #ffffff;
        } 
        .season {
            padding: 0.5rem 0.5rem 0.75rem 0rem;
        }    
    }
    @media only screen and (max-width: 358px) {
        .flex-grow.flex {
            display: flex;
            flex-direction: column;
            padding: 15px 0px;
            font-size: 12px;
            background: #ffffff;
        } 
    }