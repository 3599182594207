

.comparisonContainer {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1%;
    margin-bottom: 25px;

    &:last-child {
        margin-bottom: 25px;
    }

    border: 2px solid #777777;

    // box-shadow: 0px 0px 10px 0px #999999;

    .chartItemContainer {
            .barContainer{
                margin-top:15px;
            .itemText {
                float: left;
                width: 48%;
                font-size: 15px;
                font-weight: bold;
                height: 20px;
                line-height: 22px;
                margin-left: 1%;
            }
            .itemBarContainer {
                float: left;
                width: 50%;
                height: 22px;
                line-height: 22px;
                margin-bottom: 5px;
                margin-right: 0%;
                background: #efefef;
                .itemBar {
                    text-align: center; 
                    height: 22px;
                    line-height: 22px;
                    color:#fff;
                    font-size: 15px;
                    background: #0061a1;
                }
            }
        }
    }

    .comparisonHeader {
        width: 100%;
        display: flex;
        padding: 14px;
        background: #777777;

        .comparisonTitle {
            align-self: center;
            font-size: 18px;
            line-height: 34px;
            font-weight: bold;
            color: #fff;
        }

        .comparisonDatesContainer {
            display: flex;
            flex: 1;
            justify-content: flex-end;

            .date {
                cursor: pointer;
                width: 85px;
                line-height: 34px;
                transition: 0.3s;
                background: #f2f2f2;
                font-size: 16px;
                font-weight: bold;
                border: 1px solid #777777;
                text-align: center;
            }

            .date.active {
                background: #65ac1e;
                color: #fff;
            }
        }
    }

    .comparisonContent {
        background: #fff;
        .tab-list {
            height: 62px;
            background: none;
            margin-top: 2%;
            padding: 0% 20%;
        }

        .tab-list-item {
            margin: 0% 2%;
            height: 40px;
            align-self: flex-end;
            background: #d3d2d2;
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);

            .tabLabel {
                font-size: 16px;
            }
        }

        .tab-list-active {
            background: #929292;
        }

        .comparisonChartContainer {
            padding: 2% 2% 2% 2%;

            .title {
                font-size: 16px;
                line-height: 24px;
                font-weight: bold;
            }

            .subTitle {
                font-size: 16px;
                line-height: 26px;
                font-weight: bold;
            }

            .chartContainer {
                display: flex;
                flex-direction: column;
                padding-right: 2%;
                padding: 0px;
                background: #fff;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .comparisonContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0%;
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0px;
        }

        border: 2px solid #777777;

        // box-shadow: 0px 0px 10px 0px #999999;
        .comparisonHeader {
            width: 100%;
            display: flex;
            padding: 14px;
            background: #777777;
            flex-direction: column;

            .comparisonTitle {
                align-self: center;
                font-size: 18px;
                line-height: 34px;
                font-weight: bold;
                color: #fff;
                flex-direction: row;
                justify-content: center;
            }

            .comparisonDatesContainer {
                display: flex;
                flex: 1;
                justify-content: center;
                flex-direction: row;

                .date {
                    cursor: pointer;
                    width: 85px;
                    line-height: 34px;
                    transition: 0.3s;
                    background: #f2f2f2;
                    font-size: 16px;
                    font-weight: bold;
                    border: 1px solid #777777;
                    text-align: center;
                }

                .date.active {
                    background: #65ac1e;
                    color: #fff;
                }
            }
        }

        .chartItemContainer {
            .barContainer{
                margin-top:15px;
            .itemText {
                float: left;
                width: 48%;
                font-size: 12.5px;
                font-weight: bold;
                height: 20px;
                line-height: 22px;
                margin-left: 1%;
            }
            .itemBarContainer {
                float: left;
                width: 50%;
                height: 22px;
                line-height: 22px;
                margin-bottom: 5px;
                margin-right: 0%;
                background: #efefef;
                .itemBar {
                    text-align: center; 
                    height: 22px;
                    line-height: 22px;
                    color:#fff;
                    font-size: 15px;
                    background: #0061a1;
                }
            }
        }
    }

        .comparisonContent {
            background: #fff;


            .tab-list {
                height: 62px;
                background: none;
                margin-top: 2%;
                padding: 0% 10%;
            }

            .tab-list-item {
                margin: 0% 2%;
                height: 40px;
                align-self: flex-end;
                background: #d3d2d2;
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);

                .tabLabel {
                    font-size: 16px;
                }
            }

            .tab-list-active {
                background: #929292;
            }

            .comparisonChartContainer {
                padding: 2% 2% 2% 2%;

                .title {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: bold;
                }

                .subTitle {
                    font-size: 16px;
                    line-height: 26px;
                }

                .chartContainer {
                    display: flex;
                    flex-direction: column;
                    padding-right: 2%;
                    padding: 15px;
                    background: #fff;
                }
            }
        }
    }
}