// excess height to improve interactive area / accessibility
$height: 30px;
$thumb-height: 20px;
$track-height: 8px;

// colors
$upper-color: #8c8c8c;
$lower-color: #65ac13;
$thumb-color: #eee;
$thumb-hover-color: #eee;

$upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100% 50% / 100% $track-height no-repeat transparent;
$lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100% 50% / 100% $track-height no-repeat transparent;

// Webkit cannot style progress so we fake it with a long shadow
// on the thumb element
@function webkit-slider-thumb-shadow($i: 1) {
  $val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$upper-color};
  @if $i == 1 {
    @for $k from 2 through 1000 {
      $val: #{$val}, webkit-slider-thumb-shadow($k);
    }
  }
  @return $val;
}

.range{
  background-color: #fff;
  margin: 0 auto;
  padding: 10px;
  width: 300px;
 text-align: center;
}

.range-output{
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.e-range {
  display: block;
  appearance: none;
  width: 235px;
  margin: 0;
  height: $height;
  overflow: hidden;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

#output{
  font-size: 18px;
  font-weight: 600;
  margin-top: 6px;
}

#output1{
  font-size: 18px;
  font-weight: 600;
  margin-top: 5px;
}

// Webkit
.e-range::-webkit-slider-runnable-track {
  width: 100%;
  height: $height;
  background: $lower-background;
}

.e-range::-webkit-slider-thumb {
  position: relative;
  appearance: none;
  height: $thumb-height;
  width: $thumb-height;
  background: $thumb-color;
  border-radius: 100%;
  border: 0;
  top: 50%;
  margin-top: (-$thumb-height/2);
  box-shadow: webkit-slider-thumb-shadow();
  transition: background-color 150ms;
}

// Firefox
.e-range::-moz-range-track,
.e-range::-moz-range-progress {
  width: 100%;
  height: $height;
  background: $upper-background;
}

.e-range::-moz-range-progress {
  background: $lower-background;
}

.e-range::-moz-range-thumb {
  appearance: none;
  margin: 0;
  height: $thumb-height;
  width: $thumb-height;
  background: $thumb-color;
  border-radius: 100%;
  border: 0;
  transition: background-color 150ms;
}

// Internet Explorer
.e-range::-ms-track {
  width: 100%;
  height: $height;
  border: 0;
  // color needed to hide track marks
  color: transparent;
  background: transparent;
}

.e-range::-ms-fill-lower {
  background: $lower-background;
}

.e-range::-ms-fill-upper {
  background: $upper-background;
}

.e-range::-ms-thumb {
  appearance: none;
  height: $thumb-height;
  width: $thumb-height;
  background: $thumb-color;
  border-radius: 100%;
  border: 0;
  transition: background-color 150ms;
  // IE Edge thinks it can support -webkit prefixes
  top: 0;
  margin: 0;
  box-shadow: none;
}

.e-range:hover,
.e-range:focus {

  &::-webkit-slider-thumb {
    background-color: $thumb-hover-color;
  }
  &::-moz-range-thumb {
    background-color: $thumb-hover-color;
  }
  &::-ms-thumb {
    background-color: $thumb-hover-color;
  }
}

@media only screen and (max-width: 960px) {
  .range{
    width: 250px;
    margin: 35px auto;
  }

  .e-range {
    width: 200px;
  }
}

@media only screen and (max-width: 320px) {
  .range{
    width: 225px;
    margin: 35px auto;
  }

  .e-range {
    width: 175px;
  }
}