#trial_popup { 
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    z-index: 10000;
    display:none;
    #popup_message {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: TranslateX(-50%) TranslateY(-50%);
        background-color: #fff;
        max-height: 600px;
        max-width:650px;
        overflow-y:scroll;
        //border: 2px solid #65ac1e;
        padding: 20px;
    }  
    #popup_no img { 
        float: right;
        cursor: pointer;
    } 
    .popupRow {
        display: flex;
        flex-direction: row;
    } 
    .popupRow p {
    }

    .popupRow ol {
         font-size: 1rem;
          margin:-15px;
        li{
            list-style-type:decimal
        }
     }
     .popupRow ul {
          font-size: 1rem;
          margin:-15px;
          li{
            list-style-type:disc
        }
     }
        
}

@media only screen and (max-width: 1300px) {
	#trial_popup #popup_message {
        width: 80%;
        max-height: 500px;
        overflow: auto;
    }
}

@media only screen and (max-width: 738px) { 
    #trial_popup {
        .popupRow { 
            flex-direction: column;
        } 
        .popupRow img { 
            margin: auto; 
        }
        #popup_text-reviewmap h3 {
            text-align: center;
        } 
    }
}