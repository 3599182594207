.menuContainer {
	// padding:10px;
	// border: 1px solid #65ac1e;
}
li {
	list-style: none;
	margin: 1em 0em 0.5em 1.7em;
}
.filterContainer {
	/* TOP SEARCH HEADER */
	margin-bottom: 25px;
	.conditionalShow {
		display: block;
	}
	.desktopFilter {
		display: block;
	}
	.mobileFilter {
		display: none;
	}
	.searchHeader {
		display: flex;
		flex-direction: row;
		padding: 5px 20px;
		margin-top: 8px;
		.title {
			display: flex;
			flex: 1;
			font-size: 16px;
			font-weight: bold;
		}
		.reset {
			display: flex;
			font-size: 14px;
			font-weight: bold;
			color: #68a717;
			background: #fff;
			text-decoration: underline;
			// padding: 6px 12px;
			cursor: pointer;
			text-transform: uppercase;
		}
	}
	.searchButtonsContainer,
	.filtersButtonsContainer {
		display: flex;
		justify-content: space-between;
		padding: 5px 20px;
		.helpBox {
			width: 100px;
			background-color: #fff;
			border: 2px solid #65ac1e;
			color: #65ac1e;
			padding: 0;
		}
		&.desktop {
			display: flex;
			justify-content: center;
			.searchButton {
				width: 100%;
			}
		}
		&.mobile {	
			//display: none;
		}
	}

	.searchButton {
		font-size: 14px;
		font-weight: bold;
		text-align: center;
		color: #fff;
		background-color: #65ac1e;
		border: 1px solid #65ac1e;
		cursor: pointer;
		padding: 10px 15px;
		width: 80%;
		margin-left: 10px;
	}
	.helpButton {
		width: 15%;
	}

	
	// @media only screen and (max-width: 360px) {
	//     .searchHeader {
	//         padding: 0px 20px;
	//         margin-top: 12px;
	//         .title {
	//             font-size: 12px;
	//         }
	//         .reset {
	//             font-size: 12px;
	//         }
	//         .searchBar {
	//             input[type=text] {
	//                 font-size: 12px;
	//             }
	//         }
	//     }
	// }

	/* HEADER/RESET STYLES */
	.header {
		display: flex;
		flex-direction: row;
		padding: 5px 20px;
		.title {
			display: flex;
			flex: 1;
			font-size: 16px;
			font-weight: bold;
		}
		.reset {
			display: flex;
			font-size: 14px;
			font-weight: bold;
			color: #fff;
			background: #68a717;
			text-decoration: none;
			padding: 6px 12px;
			text-decoration: none;
			cursor: pointer;
			text-transform: uppercase;
		}
		.contentBlock {
			margin-left: 15px;
		}
		.fa-info-circle {
			color: #68a717;
			margin-left: 10px;
		}
		+ div {
			margin-bottom: 10px;
		}
	}

	.dropdownContainer {
		padding: 5px 20px;
		position: relative;
		.filtersDropdown {
			width: 100%;
			height: 30px;
			padding: 3px;
			font-size: 16px;
		}
		.dropdownPanel {
			border: 1px solid #767676;
			padding: 6px 3px 6px 6px;
			font-size: 16px;
			display: flex;
			justify-content: space-between;
			height: 30px;
			border-radius: 2px;
			.dropdownPanelIcon {
				font-size: 12px;
			}
		}
		.dropdownOptions {
			border: 1px solid #767676;
			height: 125px;
			overflow: auto;
			transition: all 0.2s ease-in-out;
			position: absolute;
			width: calc(100% - 40px);
			background-color: #ffffff;
			z-index: 10;
			&.hidden {
				height: 0;
				border: none;
			}
		}
		.dropdownItem {
			padding: 5px 10px;
			.checkbox__symbol {
				width: 15px;
				height: 15px;
			}
			.checkbox__textwrapper {
				font-size: 14px;
			}
		}
	}

	.filtersContainer {
		padding-top: 15px;
		display: block;
		&.hidden {
			display: none;
		}
	}

	// @media only screen and (max-width: 360px) {
	//     .header {
	//         padding: 10px 20px;
	//         .title {
	//             font-size: 12px;
	//         }
	//         .reset {
	//             font-size: 12px;
	//         }
	//     }
	// }

	/* SEARCH BAR */
	.searchBarContainer {
		display: flex;
		max-width: 1200px;
		justify-content: space-around;
		padding: 5px 20px;
		.searchBar {
			border: 3px solid #dddddd;
			input[type='text'] {
				padding: 7px;
				font-size: 16px;
				width: calc(100% - 50px);
				border: none;
			}
			button {
				float: right;
				width: 45px;
				height: 25px;
				margin-top: 5px;
				padding: 0px 5px;
				font-size: 17px;
				border: none;
				background: #fff;
				border-left: 1px solid #dddddd;
				cursor: pointer;
			}
			button:focus,
			input:focus {
				outline: none;
			}
			img {
				width: 15px;
			}
			li {
				cursor: pointer;
			}
		}

		.autocomplete-dropdown-container {
			font-size: 16px;
			color: #000;
			line-height: 22px;
			margin-left: 5px;
		}
	}

	// @media only screen and (max-width: 360px) {
	//     .searchBarContainer {
	//         margin-bottom: -5px;
	//         .searchBar {
	//             input[type=text] {
	//                 font-size: 12px;
	//             }
	//             button {
	//                 height: 17px;
	//             }
	//         }
	//     }
	// }

	/* YEAR SELECTOR */
	.yearSelectorContainer {
		display: flex;
		flex-direction: row;
		padding: 0px 20px;
		.yearSelect {
			border: 1px solid #65ac1e;
			padding: 7px 26px;
			transition: 0.3s;
			font-size: 16px;
			font-weight: 600;
			text-align: center;
			cursor: pointer;
			&:hover {
				background: #65ac1e;
				color: #000;
			}
			&.active {
				background: #65ac1e;
				color: #fff;
			}
		}
	}

	//     @media only screen and (max-width: 360px) {
	//         .yearSelectorContainer {
	//             .yearSelect {
	//                 padding: 5px 26px;
	//                 font-size: 12px;
	//                 margin-top:-4px;
	//         }
	//     }
	// }

	/* TRIAL TYPE CHECKBOX CONTAINER */
	.trialCBContainer {
		padding: 0px 20px;
	}

	.showMoreOptions {
		display: flex;
		font-size: 14px;
		font-weight: bold;
		color: #65ac1e;
		text-decoration: none;
		cursor: pointer;
		padding: 20px 10px;
	}
}

// @media only screen and (max-width: 360px) {
//     .filterContainer{
//         .showMoreOptions {
//             font-size: 12px;
//             padding: 10px 10px;
//         }
//     }
// }

/* DONE BUTTON */
.doneContainer {
	display: flex;
	width: 100%;
	padding: 20px;
	justify-content: center;
	.doneBtn {
		font-size: 16px;
		font-weight: bold;
		text-align: center;
		color: #fff;
		background-color: #65ac1e;
		border: 1px solid #65ac1e;
		cursor: pointer;
		padding: 10px 25px;
		width: 100%;
	}

	.doneBtnmob {
		display: none;
	}
}

@media only screen and (max-width: 1024px) {
	.menuContainer {
		.filterContainer {
			//display: none;
		}
		.searchHeader {
			.reset {
				color: #fff;
				background: #68a717;
				text-decoration: none;
				padding: 6px 12px;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.menuContainer {
		padding-top: 10px;
		.searchHeader {
			margin-top: 0;
		}
		.filterContainer {
			.header {
				padding: 10px 20px;
			}
			// height: 600px;
			// overflow-y: scroll;
			// position: absolute;
			background-color: white;
			width: 100%;
			// height: 100%;
			top: 0;
			&.hidden {
				display: none;
			}
			.desktopFilter {
				display: none;
			}
			.mobileFilter {
				display: block;
				padding: 5px 20px;
				&.hidden {
					display: none;
				}
			}
			.conditionalShow {
				display: block;
				&.hidden {
					display: none;
				}
			}
			.searchButton {
				width: 100%;
			}
			.doneContainer {
				display: flex;
				width: 100%;
				padding: 10px 20px;
				justify-content: center;

				// .doneBtnmob {
				//     font-size: 16px;
				//     font-weight: bold;
				//     text-align: center;
				//     color: #fff;
				//     background-color: #65ac1e;
				//     border:1px solid #65ac1e;
				//     cursor: pointer;
				//     padding: 10px 25px;
				//     width: 100%;
				//     display:block;
				// }
			}
			.searchButtonsContainer {
				display: none;
			}
			.filtersContainer {
				display: block;
				&.hidden {
					display: none;
				}
			}
			.filtersButtonsContainer {
				.helpBox {
					display: none;
				}
				&.desktop {
					display: none;
				}
				&.mobile {
					display: flex;
				}
			}
		}
	}
}

@media only screen and (max-width: 407px) {

	.filterContainer {
		.yearSelectorContainer {
			display: flex;
			flex-direction: row;
			padding: 0px 16px;
			.yearSelect {
				border: 1px solid #65ac1e;
				padding: 7px 21px;
				transition: 0.3s;
				font-size: 14px;
				font-weight: 600;
				text-align: center;
				cursor: pointer;
				&:hover {
					background: #65ac1e;
					color: #000;
				}
				&.active {
					background: #65ac1e;
					color: #fff;
				}
			}
		}
	}
}

@media only screen and (max-width: 480px) {

	.searchBarContainer {
		padding: 5px 20px;
		.searchBar {
			border: 3px solid #dddddd;
			input[type='text'] {
				width: calc(100% - 50px) !important; 
				}
			}
		}
	}

// @media only screen and (max-width: 425px) {
//     .filterContainer {
//         height: 525px;
//         overflow-y: scroll;
// }
// }

// @media only screen and (max-width: 360px) {
//         .filterContainer {
//             height: 550px;
//             overflow-y: scroll;
//             .doneContainer {
//                 padding: 5px 20px;
//                 .doneBtn {
//                     display: none;
//                 }
//                 .doneBtnmob {
//                     font-size: 12px;
//                     padding: 8px 25px;
//                 }
//             }
// }
// }

// @media only screen and (max-width: 320px) {
//     .filterContainer {
//         height: 425px;
//         overflow-y: scroll;
// }
// }

//tooltip

.special-text {
	position: relative;
	cursor: pointer;
}

/* Show the tooltip on hover */
.special-text:hover .special-text__tooltip {
	visibility: visible;
	opacity: 1;
}

.special-text__tooltip {
	font-family: 'Helvetica Neue LT W06_45 Light',
		'Helvetica Neue LT W06_55 Roman', sans-serif;
	font-size: 16px;
	line-height: 20px;
	position: absolute;
	background: #fff;
	color: #000;
	width: 240px;
	bottom: 100%;
	margin-bottom: 13px;
	margin-left: -27px;
	padding: 10px;
	box-shadow: 0 2px 7px #cdcdcd;
	border-radius: 6px;
	visibility: hidden;
	opacity: 0;
	text-align: left;
	transition-property: opacity;
	transition-duration: 0.5s;
	transition-timing-function: linear;
	transition-delay: initial;
}

/* The bordered part of the triangle */
.special-text__tooltip::before,
.special-text__tooltip::after {
	content: '';
	position: absolute;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	top: 100%;
}

.special-text__tooltip::before {
	border-top: 10px solid #fff;
	margin-top: 0px;
	filter: drop-shadow(0px 2px 1px #cdcdcd);
	-webkit-filter: drop-shadow(0px 2px 1px #cdcdcd);
}

.k-animation-container.k-animation-container-relative {
	padding-top: 30px;
}