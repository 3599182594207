.footer {
	float: left;
	padding: 20px;
	width: 100%;
	background-color:#ffffff; 
	.header {
		width: 100%;
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 20px;
		color:#646464;
	}
	.text {
		font-size: 16px; 
		color:#646464;
		margin-bottom: 20px;
	}
	.nav {
		margin-bottom: 20px;
		font-weight:bold;
		font-size:16px;
	}
	a {
		text-decoration: none;
		color: #646464;
	}
}
@media only screen and (max-width: 776px) { 
	.footer {
		.nav {
			font-size:14px;
		}
	}
}

@media only screen and (max-width: 570px) { 
	.footer-text,
	.footer-header,
	.app-footer a {
		text-align: center;
	}

	.hide-mobile {
		display: none;
	}
}
