.share-button {
  margin-right: 10px;
  margin-top:2px;

  }

  .boxshad {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px !important;
  }

.c013{
  position: inherit !important;
}

.c018{
  position: inherit !important;
}

.c0113{
  position: inherit !important;
}

.c0118{
  position: inherit !important;
}

.c0123{
  position: inherit !important;
}

.c0128{
  position: inherit !important;
}

.c0133{
  position: inherit !important;
}

.c0138{
  position: inherit !important;
}

.c0143{
  position: inherit !important;
}

.c0148{
  position: inherit !important;
}

.c0153{
  position: inherit !important;
}

.c0158{
  position: inherit !important;
}

.c0163{
  position: inherit !important;
}

.c0168{
  position: inherit !important;
}