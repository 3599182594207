.comparisonTrialsPageContainer {
    background: #fff;
    .trialList{
        padding: 15px;
        .individualTrialSummaryBody{ 
            margin-bottom: 30px;
            padding: 15px 15px 15px 15px;
            &:last-child { margin-bottom: 0px }
            box-shadow: 0px 0px 7px 0px #999999;


            .chartItemContainer{
                .barContainer{
                    .itemText{
                        width: 14%;
                        margin-left: 2%;
                    }
                    .itemBarContainer{
                        width: 82%;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 1024px) {
    .comparisonTrialsPageContainer {
        .trialList{
            padding: 15px;
            .individualTrialSummaryBody{ 
                margin-bottom: 30px;
                &:last-child { margin-bottom: 0px }
                box-shadow: 0px 0px 7px 0px #999999;
                .chartItemContainer{
                    .barContainer{
                        .itemText{
                            width: 22%;
                            margin-left: 2%;
                        }
                        .itemBarContainer{
                            width: 75%;
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 767px) {
    .comparisonTrialsPageContainer {
        .trialList{
            padding: 15px;
            .individualTrialSummaryBody{ 
                margin-bottom: 30px;
                &:last-child { margin-bottom: 0px }
                box-shadow: 0px 0px 7px 0px #999999;
                .chartItemContainer{
                    .barContainer{
                        .itemText{
                            width: 43%;
                            margin-left: 2%;
                        }
                        .itemBarContainer{
                            width: 54%;
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 400px) {
    .comparisonTrialsPageContainer {
        .trialList{
            padding: 15px;
            .individualTrialSummaryBody{ 
                margin-bottom: 30px;
                &:last-child { margin-bottom: 0px }
                box-shadow: 0px 0px 7px 0px #999999;
                .chartItemContainer{
                    .barContainer{
                        .itemText{
                            width: 50%;
                        }
                        .itemBarContainer{
                            width: 47%;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 320px) {
    .comparisonTrialsPageContainer {
        .trialList{
            padding: 15px;
            .individualTrialSummaryBody{ 
                margin-bottom: 30px;
                &:last-child { margin-bottom: 0px }
                box-shadow: 0px 0px 7px 0px #999999;
                .chartItemContainer{
                    .barContainer{
                        .itemText{
                            width: 58%;
                        }
                        .itemBarContainer{
                            width: 40%;
                        }
                    }
                }
            }
        }
    }
}