.chartTabsNavBar {
	display: flex;
	width: 100%;
	background-color: #bbbaba;
	.trialNavContainer {
		margin-left: 15px;
	}
	.chartTabsNavItem {
		background: #fff;
		padding: 8px 16px;
		font-size: 16px;
		margin: 15px 7px;
		cursor: pointer;
		width: 10%;
		text-align: center;
	}
}

.ChartX-axis{
	text-align: center;
}

@media only screen and (max-width: 1024px) {
	.chartTabsNavBar {
		.trialNavContainer {}
		.chartTabsNavItem {
			background: #fff;
			padding: 8px 16px;
			font-size: 16px;
			margin: 15px 7px;
			cursor: pointer;
			width: 20%;
			text-align: center;
		}
	}
}

@media only screen and (max-width: 767px) {
	.chartTabsNavBar {
		display: flex;
		width: 100%;
		background-color: #bbbaba;
		justify-content: center;
		.trialNavContainer {
			margin-left: 0px;
		}
		.chartTabsNavItem {
			background: #fff;
			padding: 6px 12px;
			font-size: 16px;
			margin: 15px 7px;
			cursor: pointer;
			width: 30%;
			text-align: center;
		}
	}
}

/* VERY TOP INFO CONTAINER */

.individualTrialPageContainer {
	background: #fff;
	padding: 20px;
	.indTrialHeaderContainer {
		display: flex;
		flex-direction: column;
		padding: 20px 50px;
		margin-bottom: 15px;
		.infoContainer {
			display: flex;
			.left {
				display: flex;
				.leftHeader {}
				.rightHeader {
					padding-left: 50px;
				}
				.indTrialText {
					font-size: 18px;
					padding: 5px;
					text-transform: capitalize;
				}
				.indTrialHead {
					font-size: 18px;
					font-weight: bold;
				}
			}
			.right {
				flex: 1;
				.popup {
					position: fixed;
					z-index: 1;
					padding-top: 200px;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					overflow: auto;
					background-color: rgba(0, 0, 0, 0.4);
					background-color: rgba(0, 0, 0, 0.4);
					.popup_inner {
						position: relative;
						background-color: #fefefe;
						margin: auto;
						padding: 0;
						border: 1px solid #888;
						width: 40%;
						box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
						-webkit-animation-name: animatetop;
						-webkit-animation-duration: 0.4s;
						animation-name: animatetop;
						animation-duration: 0.4s;
						@-webkit-keyframes animatetop {
							from {
								top: -300px;
								opacity: 0
							}
							to {
								top: 0;
								opacity: 1
							}
						}
						@keyframes animatetop {
							from {
								top: -300px;
								opacity: 0
							}
							to {
								top: 0;
								opacity: 1
							}
						}
						h2 {
							font-size: 18px;
							font-weight: bold;
							line-height: 24px;
							padding: 14px;
							color: #fff;
						}
						.images {
							margin-bottom: 5px;
							padding: 10px;
							width: 100%;
						}
						.close {
							color: white;
							float: right;
							font-size: 28px;
							font-weight: bold;
						}
						.close:hover,
						.close:focus {
							color: #000;
							text-decoration: none;
							cursor: pointer;
						}
						.modal-header {
							padding: 2px 16px;
							background-color: #454545;
							color: #fff;
						}
						.modal-body {
							padding: 2px 16px;
						}
					}
				}
				@media only screen and (max-width: 1024px) {
					.popup {
						.popup_inner {
							width: 70%
						}
					}
				}
				@media only screen and (max-width: 767px) {
					.popup {
						.popup_inner {
							width: 70%
						}
					}
				}
				/* button section */
				.buttonsContainer {
					display: flex;
					/* change to flex when we want to show these buttons */
					justify-content: flex-end;
					height: 110px;
					.button {
						background-repeat: no-repeat;
						margin: 3px 0 0 0;
						cursor: pointer;
					}
					.PDFButton {
						height: 36px;
					}
					.button:nth-child(2) {
						width: 34px;
						height: 36px;
						background-image: url("../../images/pdf.png");
					}
					.button:nth-child(3) {
						width: 42px;
						height: 36px;
						background-image: url("../../images/printer.png");
					}
					.button-share {
						width: 34px;
						height: 36px;
						background-image: url("../../images/trio.png");
						margin: 5px 10px;
						cursor: pointer;
					}
				}
			}
			
		}

		.apt_infoContainer {
			display: flex;
			.left {
				display: flex;
				.leftHeader {}
				.rightHeader {
					padding-left: 50px;
				}
				.indTrialText {
					font-size: 18px;
					padding: 5px;
					text-transform: capitalize;
				}
				.indTrialHead {
					font-size: 18px;
					font-weight: bold;
				}
			}
			// display: flex;
			// flex-direction: column;
			// margin-left: 5px;
			// .row {
			// 	display: flex;
			// 	flex-direction: row;
			// 	flex-wrap: wrap;
			// 	width: 100%;
			//   }
			  
			//   .column {
			// 	display: flex;
			// 	flex-direction: column;
			// 	flex-basis: 100%;
			// 	flex: 1;
			//   }
			// p {
			// 	margin-bottom: 0px;
			// }
		}

		
		.alertContainer {
			display: flex;
			flex-direction: column;
			background: #fff;
			padding: 20px 30px;
			box-shadow: 0px 0px 10px 0px #e0dfdf;
			.top {
				display: flex;
				.alertHeader {
					flex: 1;
					font-weight: bold;
					font-size: 24px;
				}
				.closeAlert {
					color: #999999;
					font-weight: bold;
					font-size: 30px;
					cursor: pointer;
				}
			}
			p {
				font-size: 16px;
			}
		}
	}
	@media only screen and (max-width: 1024px) {
		.indTrialHeaderContainer {
			padding: 20px;
			.infoContainer {
				flex-direction: column;
			}
		}
	}
	@media only screen and (max-width: 767px) {
		.indTrialHeaderContainer {
			padding: 20px;
			margin-bottom: 0px;
			.infoContainer {
				flex-direction: column;
				.left {
					flex-direction: column;
					.rightHeader {
						padding-top: 10px;
						padding-left: 0px;
						flex-direction: column;
						justify-content: flex-start;
					}
					.indTrialText {
						font-size: 16px;
						padding: 0px;
					}
					.indTrialHead {
						font-size: 16px;
						font-weight: bold;
					}
				}
				.right {
					flex-direction: column;
					.buttonsContainer {
						padding-top: 10px;
						display: flex; /* change to flex when we want to show these buttons */
						justify-content: flex-end;
						height: 50px;
					}
				}
			}
		}
	}

	.weatherContainer {
        padding: 10px 30px;
		white-space: pre-wrap;
    }
    
	.hybridContainer {
		display: flex;
		flex-direction: column;
		background: #454545;
		padding: 20px 50px;
		margin-bottom: 15px;
		.infoContainer {
			display: flex;
			.left {
				display: flex;
				.leftHeader {}
				.rightHeader {
					padding-left: 50px;
				}
				.indTrialText {
					font-size: 16px;
					padding: 5px;
				}
				.indTrialHead {
					font-size: 20px;
					font-weight: bold;
					color: #fff;
					line-height: 25px;
				}
			}
			.right {
				flex: 1;
				justify-content: flex-end;
				.chartTabsNavBar {
					display: flex;
					justify-content: flex-end;
					width: 100%;
					background-color: #454545;
					.chartTabsNavItem {
						background: #fff;
						padding: 6px 12px;
						font-size: 16px;
						font-weight: bold;
						margin: 0px 1px;
						cursor: pointer;
					}
					.active {
						background: #5b9f05;
						color: #fff;
					}
				}
			}
		}
	}
	@media only screen and (max-width:767px) {
		.hybridContainer {
			margin-bottom: 0px;
			.infoContainer {
				flex-direction: column;
				.left {
					justify-content: center;
					.leftHeader {
						justify-content: center;
					}
					.rightHeader {
						padding-left: 0px !important;
					}
					.indTrialText {}
					.indTrialHead {
						font-size: 16px !important;
						font-weight: bold;
						margin-bottom: 10px;
					}
				}
				.right {
					.chartTabsNavBar {
						display: flex;
						justify-content: center !important;
						.chartTabsNavItem {
							padding: 6px 25px;
						}
					}
				}
			}
		}
	}
	.tabChartContainer {
		display: flex;
		flex-direction: column;
		padding: 20px 50px;
		background: #fff;
		border: 1px solid #6f6e6e;
		border-top: 0;
		margin-bottom: 20px;
		.title {
			font-size: 28px;
			font-weight: bold;
			padding-bottom: 10px;
		}
		.subTitle {
			font-size: 22px;
			font-weight: bold;
			padding-bottom: 10px;
		}
		.chartLabel {
			font-size: 16px;
			margin: 20px 0;
			line-height: 24px;
			.chartLabelUnit {
				display: flex;
            	flex-direction: row;
				font-weight: bold;
				.bracket-text {
                    font-size:12px;
                    font-weight: normal;
					margin-top:3px;
					margin-left:5px;
                }
			}
			.chartLabelSwathType {
				font-weight: bold;
			}
		}
		.chartItemContainer {
			.itemText {
				float: left;
				width: 23%;
				padding-right: 2%;
				margin-top: 7px;
				height: 44px;
				.itemHybrid {
					font-size: 14px;
					font-weight: bold;
				}
				.itemSwathType {
					font-size: 14px;
					font-weight: normal;
				}
			}
			.itemBarContainer {
				float: left;
				width: 75%;
				height: 44px;
				line-height: 44px;
				margin-bottom: 5px;
				.itemBar {
					text-align: center;
					height: 40px;
					line-height: 40px;
					color: #fff;
					font-size: 16px;
					font-weight: 600;
					//background:#0061a1;
				}
			}
		}
		.itemLabel{
			font-size: 14px;
			margin: 20px 0;
		}
	}
	@media only screen and (max-width: 1024px) {
		.tabChartContainer {
			padding: 20px;
			.subTitle {
				font-size: 16px;
				font-weight: bold;
				padding-bottom: 10px;
			}
			.chartItemContainer {
				.itemText {
					float: left;
					width: 20%;
					font-size: 16px;
					font-weight: bold;
					height: 20px;
					line-height: 20px;
					margin-top: 7px;
				}
			}
		}
	}
	@media only screen and (max-width: 767px) {
		.tabChartContainer {
			padding: 20px;
			.subTitle {
				font-size: 16px;
				font-weight: bold;
				padding-bottom: 10px;
			}
			.chartItemContainer {
				.itemText {
					float: left;
					width: 35%;
					font-size: 14px;
					font-weight: bold;
					height: 20px;
					line-height: 18px;
					margin-top: 3px;
				}
				.itemBarContainer {
					float: left;
					width: 65%;
					height: 44px;
					line-height: 44px;
					margin-bottom: 5px;
				}
			}
		}
	}
	@media only screen and (max-width: 450px) {
		.tabChartContainer {
			padding: 20px;
			.subTitle {
				font-size: 16px;
				font-weight: bold;
				padding-bottom: 10px;
			}
			.chartItemContainer {
				.itemText {
					float: left;
					width: 45%;
					font-size: 14px;
					font-weight: bold;
					height: 20px;
					line-height: 18px;
					margin-top: 3px;
				}
				.itemBarContainer {
					float: left;
					width: 55%;
					height: 44px;
					line-height: 44px;
					margin-bottom: 5px;
				}
			}
		}
	}
	/* FIXED TABLE CONTAINER  TO DO*/

	.trialTableContainer2019 {
		background: #fff;
		text-align: left;
		margin-bottom: 20px;
	
		overflow: auto;
		height: auto;
		width: 100%;
		overflow-x: scroll;
	
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;
		.trialTable2019 {
			 //border-collapse: collapse;
			border-spacing:0px;
			color: #454545;
			width: 100%;
			.seedTreatmentLight{
				background: #FCCD83;
			}
			.preseedLight{
				background: #faeede;
			}
			.incropLight{
				background: #e8f4de;
			}
			.incropLight1{
				background: #d9eec5;
			}
			.fungTTLight{
				background: #e6e9e2;
			}
			.harvestAidLight{
				background: #fae4e6;
			}
			.insecticideTTLight{
					background: #f2ebfb;
				}
			tr {
				font-size: 16px;
				white-space: nowrap;
	
				th {
					padding: 10px;
					color: white;
					background-color: #6f6e6e;
				}
				td {
					padding: 10px;
					background-color: white;
					//box-shadow: -1px -1px #cccc, 1px 1px #cccc;
					border:0.7px solid #fff;
				}
	
				td:first-child,
				th:first-child {
					position: sticky;
					left: 0;
					z-index: 1;
				}
				td:nth-child(2),
				th:nth-child(2) {
					position: sticky;
					left: 140px;
					z-index: 1;
					/* border:1px solid;  */
				}
				th {
					position: sticky;
					top: 0;
					z-index: 2;
				}
				th:first-child,
				th:nth-child(2) {
					z-index: 3;
				}

				.noTopBorder {
					box-shadow: -1px 0px #fff, 1px 1px #fff;
				}
	
				td:nth-child(2) {
					border-left: 1px solid #fff;
				}
				.seedTreatment{
					background: #CC800A;
					color: #fff;
					font-weight: 600;
				}
				.preseed{
					background: #f39501;
					color: #fff;
					font-weight: 600;
				}
				.incrop{
					background: #65ac1e;
					color: #fff;
					font-weight: 600;
				}
				.fungTT{
					background: #375d10;
					color: #fff;
					font-weight: 600;
				}
				.harvestAid{
					background: #ac0516;
					color: #fff;
					font-weight: 600;
				}
				.insecticideTT{
					background: #563890;
					color: #fff;
					font-weight: 600;
				}
			}
		}
	}

	.trialTableContainer {
		width: 100%;
		overflow-y: hidden;
        overflow-x: auto;
		background: #fff;
		text-align: left;
		margin-bottom: 20px;
		.trialTablePDFWrapper {
			width: 1100px;
			padding-right: 20px;
			.trialTableStatic {
				border-collapse: collapse;
				color: #454545;
				margin-left: 205px;
				width: 100%;
				.headerRow {
					td,
					th {
						border: none;
						z-index: 100;
					}
				}
				.headCol {
					min-width: 205px;
					height: 40px;
					margin-left: -205px;
					margin-top: 0px;
					position: absolute;
					background: #edf6fd;
				}
				.headColPDF {
					position: relative;
				}
				.headColDark{
					background-color: #def0fc;
				}
				.headColLight {
					background-color: #edf6fd;
				}
				.headColHead {
					background: #6f6e6e;
					font-weight: bold;
					color: #fff;
					padding-left: 11px;
					font-size: 16px;
					height: 57.7px;
					margin-top: 0px;
					text-align: left;
				}
				td,
				th {
					margin: 0;
					border-top-width: 0px;
					white-space: nowrap;
				}
				.cellHead {
					background: #6f6e6e;
					font-weight: bold;
					color: #fff;
					vertical-align: top;
					img {
						width: 16px;
						margin-left: 10px;
					}
				}
				.cell {
					//border: 1px solid #454545;
					padding: 10px;
					font-size: 16px;
				}
				.tooltip .tooltiptext {
					right: 0px;
				}
			}
			.trialTablePDF {
				width: auto;
				margin-left: 0;
				margin-right: 20px;
			}
		}
		.trialTable {
			border-collapse: collapse;
			color: #454545;
			width: 100%;
			.headerRow {
				td,
				th {
					border: none;
				}
			}
			.headCol {
				min-width: 200px;
				height: 40px;
				margin-top: -1px;
				background: #fff;
			}
			.headColDark {
				background-color: #def0fc;
			}
			.headColLight {
				background-color: #edf6fd;
			}
			.headColHead {
				background: #6f6e6e;
				font-weight: bold;
				color: #fff;
				padding-left: 11px;
				font-size: 16px;
				height: 40px;
				margin-top: 0px;
				text-align: left;
			}
			td,
			th {
				margin: 0;
				border-top-width: 0px;
				white-space: nowrap;
			}
			.cellHead {
				background: #6f6e6e;
				font-weight: bold;
				color: #fff;
				vertical-align: top;
				img {
					width: 16px;
					margin-left: 10px;
				}
			}
			.cell {
				//border: 1px solid #454545;
				padding: 10px;
				font-size: 16px;
			}
			.cellPDF {
				font-size: 12px;
			}
		}
		// .tableCover {
		//     width: 200px;
		//     height: 40px;
		//     position: absolute;
		//     margin-left: -200px;
		//     background: #fff;
		//     margin-top: -20px;
		//     z-index: -1;
		// }
	}
	.trialTableContainerPDF {
		overflow-y: visible;
	}
	/* TRIAL TESTIMONIAL */
	.testimonialContainer {
		display: flex;
		padding: 0px;
		background: #e9e7e7;
		.photo {
			padding: 25px;
			img {
				width: 182px;
			}
		}
		.testimonialTextContainer {
			display: flex;
			padding: 12px;
			flex-direction: column;
			margin-left: -15px;
			width: 100%;
			.testimonialText {
				white-space: pre-wrap;
				display: flex;
				padding: 10px;
				flex-direction: column;
				margin: -15px 0px -15px 0px;
			}
			.testimonialQuoteImg {
				display: flex;
				padding: 10px;
			}
			.commentSignature {
				margin-left: 12px;
			}
		}
	}

	.metadata-div {
		margin-top: 3px;
		text-align: left;
		font-size: 14px;
		.metadata-key {
			font-weight: bold;
		}
	}


	@media only screen and (max-width: 1024px) {
		.testimonialContainer {
			padding: 10px;
			.photo {
				text-align: center;
				padding: 10px;
			}
			.testimonialTextContainer {
				display: flex;
				padding: 10px;
				flex-direction: column;
				margin-left: -6px;
			}
		}
	}
	.apt_table {
		width: 100%;
		overflow-y: auto;
		background: #fff;
		text-align: left;
        margin-bottom: 20px;
        .trialTableStatic {
			border-spacing: 0px;
			width: 100%;
		.headColHead {
			background: #6f6e6e;
			font-weight: bold;
			color: #fff;
			padding-left: 11px;
			font-size: 16px;
			height: 57.7px;
			margin-top: 0px;
			text-align: left;
		}
		td,
		th {
			margin: 0;
			border-top-width: 0px;
			white-space: nowrap;
		}
		.cellHead {
			background: #6f6e6e;
			font-weight: bold;
			color: #fff;
			vertical-align: top;
			img {
				width: 16px;
				margin-left: 10px;
			}
		}
		.cell {
			padding: 10px;
			font-size: 16px;
			width: auto;
		}
		.trialTablePDF {
			width: auto;
			margin-left: 0;
			margin-right: 20px;
		}
    }
	}
	/* SLIDER CONTAINER */
	.sliderContainer {
		height: auto;
		padding: 32px;
		background: #fff;
		margin-top: 15px;
		.slick-track {
			position: relative;
			display: flex;
			align-items: center;
		}
		.slick-next:before,
		.slick-prev:before {
			color: #333;
		}
		.slick-slide {
			display: flex;
			justify-content: center;
			text-align: center;
		}
		.slide {
			display: flex;
			flex-direction: column;
			padding-left:15px;
			padding-right:15px;
			.img-container {
				height: 450px;
				width: 350px;
				display:flex;
				position:relative;
				background-color: #e9e7e7;	
				align-items: center;
				justify-content:center;
				img {
					max-width: 100%;
					cursor:pointer;
					display: block;
					max-height: 450px;
					max-width: 340px;
				}
				.play-button {
					position: absolute;
					top: 50%; left: 50%;
					transform: translate(-50%,-50%);
					width: 96px;
					height: 96px;
				}
			}
			.slideText {
				font-size: 18px;
				text-align: center;
				padding-top: 10px;
			}
		}
	}
	
	.carousel-pdf-button{
		font-weight: bold;
		height: 29px; 
		width: 40%;
		background: #5b9f05; 
		text-align: center; 
		color: white; 
		font-size: 15px;
		vertical-align: middle;
		padding-top:4px;
	}

	  
	@media only screen and (max-width: 738px) {
		.testimonialContainer {
			flex-direction: column;
			.photo {
				text-align: center;
			}
			.testimonialTextContainer {
				display: flex;
				padding: 25px;
				flex-direction: column;
				margin-left: -6px;
				margin-top: -16px;
			}
		}

		.apt_table {
			width: 100%;
			overflow-y: auto;
			background: #fff;
			text-align: left;
			margin-bottom: 20px;
			.trialTableStatic {
				border-spacing: 0px;
				width: 100%;
			.headColHead {
				background: #6f6e6e;
				font-weight: bold;
				color: #fff;
				padding-left: 11px;
				font-size: 16px;
				height: 57.7px;
				margin-top: 0px;
				text-align: left;
			}
			td,
			th {
				margin: 0;
				border-top-width: 0px;
				white-space: pre-wrap;
			}
			.cellHead {
				background: #6f6e6e;
				font-weight: bold;
				color: #fff;
				vertical-align: top;
				img {
					width: 16px;
					margin-left: 10px;
				}
			}
			.cell {
				padding: 10px;
				font-size: 16px;
				width: auto;
			}
			.trialTablePDF {
				width: auto;
				margin-left: 0;
				margin-right: 20px;
			}
		}
		}

	}

	@media only screen and (max-width: 1024px) {
		.sliderContainer {
			padding: 40px 25px;
			.slide {
				img {
					cursor:pointer;
					display: block;
					margin-left: auto;
					margin-right: auto;
					max-height: 350px;
				}
				.play-button {
					position: absolute;
					display: inline;
					justify-content: center;
					align-items: center;
					bottom: 30%;
					transform: translate(-50%,-50%);
					width: 96px;
					height: 96px;
				}
				.slideText {
					font-size: 16px;
					text-align: center;
				}
			}
		}

	}
	@media only screen and (max-width: 767px) {
		.sliderContainer {
			margin-bottom: -30px;
			.slick-track {
				position: relative;
				display: flex;
				align-items: center;
			}
			.slide {
				width: 99% !important;
				img {
					width: 100%;
					display: block;
					margin-left: auto;
					margin-right: auto;
				}
				.play-button {
					position: absolute;
					bottom: 30%;
					display: inline;
					justify-content: center;
					align-items: center;
					transform: translate(-50%,-50%);
					width: 96px;
					height: 96px;
				}
				.slideText {
					font-size: 15px;
					text-align: center;
				}
			}
		}
	}
	.slick-dots {
		bottom: -50px;
		width: 100%;
		margin: 0px;
		list-style: none;
		text-align: center;
	}
	.downloadReportContainer {
		display: flex;
		justify-content: center;
		text-align: center;
		background: #fff;
		padding-bottom: 15px;
		.downloadLink {
			text-decoration: none;
		}
		.downloadButton {
			width: 250px;
			height: 45px;
			font-size: 15px;
			font-weight: bold;
			color: #fff;
			background: #5b9f05;
			line-height: 45px;
			margin-top: 20px;
			cursor: pointer;
		}
	}
}

@media only screen and (max-width: 767px) {
	.individualTrialPageContainer {
		padding: 20px 0px;
	.trialTableContainer2019 {
		.trialTable2019 {
			tr {
				font-size: 12px;
				white-space: nowrap;
				td:nth-child(2),
				th:nth-child(2) {
					position: sticky;
					left: 120px;
				}
			}
		}
	}

	.fertility {
		margin: 0px 0px 20px 10px;
	}
}
}

.displayNone {
	display: none;
}

.fertility{
	margin: 0px 0px 20px 0px;
	font-size: 16px;
}