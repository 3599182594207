.navBarlinks {
	display: flex;
	justify-content: center;
    width: calc(100% - 500px);
	align-items: center;
	.help {
		float: right;
		margin-right: 12px;
		margin-top: 0px;
		height: 30px;
	}
}
.navBar {
	width: 100%;
	height: 56px;
	margin: 0px;
	background-color: white;
	z-index: 10;
	font-size: 14px;
	transition: 0.4s;
	padding: 0;
	li {
		display: inline;
		list-style-type: none;
		margin: 0;
		color: #65ac1e;
		font-weight: bold;
		text-decoration: none;
		padding-left: 30px;
		padding-right: 0px;
		transition: 0.3s;
		&:hover {
			color: #000;
		}

		.active {
			color: #2e2e2e;
		}
		#home:before {
			// font-family: "BASFIconFontNewStyle";
			// content: "\e91d";
			padding-right: 5px;
			font-size: 1.125rem;
		}
		#home {
			margin-left: 10px;
		}
	}
	&.sticky {
		position: fixed;
		top: 0;
		width: 100%;
		box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
	}
	#navItemsContainer {
		display: flex;

	}

	/* MOBILE NAV STYLES */
	.mobileNavContainer {
		display: none;
		flex-direction: column;
		background-color: white;
		width: 100%;
		z-index: 10;
		font-size: 14px;
		-webkit-transition: 0.4s;
		-o-transition: 0.4s;
		transition: 0.4s;
		.itemContainerTop {
			display: flex;
			.bars {
				display: flex;
				justify-content: space-between;
				flex: 1;
				width: 75px;
				height: 25px;
				margin-top: 10px;
				margin-left: 10px;
				.navMenu {
					width: 75px;
					background-image: url(../../images/ddImage.png);
					background-repeat: no-repeat;
					cursor: pointer;
				}
				.buttonContainer {
					display: none;
					width: 160px;
					justify-content: space-between;
					margin-right: 15px;
					.helpBox {
						width: 75px;
						background-color: #fff;
						border: 2px solid #65ac1e;
						color: #65ac1e;
						padding: 0;
					}
					.searchButton {
						font-size: 14px;
						font-weight: bold;
						text-align: center;
						color: #fff;
						background-color: #65ac1e;
						border: 1px solid #65ac1e;
						cursor: pointer;
						width: 75px;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
			.closeBtn {
				display: flex;
				flex: 1;
				width: 75px;
				animation: fadein 1s;
				-moz-animation: fadein 1s;
				-webkit-animation: fadein 1s;
				-o-animation: fadein 1s;
				img {
					width: 27px;
					height: 27px;
					margin-left: 16px;
					margin-top: 7px;
					border: 1px solid #2e2e2e;
					border-radius: 50%;
					padding: 3px;
					cursor: pointer;
				}
			}
			.filterBtnContainer {
				display: flex;
				justify-content: flex-end;
				flex: 1;
				.filterBtn {
					display: flex;
					background: #615f5f;
					padding: 0px 20px;
					margin: 5px 5px 0px 0px;
					height: 35px;
					color: #fff;
					line-height: 35px;
					font-size: 16px;
					cursor: pointer;
					text-transform: uppercase;
				}
			}
		}
		li a {
			margin-left: 10px;
		}
	}

	#filterContainer {
		display: flex;
		flex-direction: column;
		max-height: auto;
		z-index: 999;
		overflow: hidden;
		background: #fff;
		-webkit-transition: max-height 0.5s ease 0s, color 0.5s ease 0s;
		transition: max-height 0.5s ease 0s, color 0.5s ease 0s;
		box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.5);
		&.collapsed {
			max-height: 0px;
			-webkit-transition: max-height 0.5s ease 0s, color 0.5s ease 0s;
			transition: max-height 0.5s ease 0s, color 0.5s ease 0s;
		}
	}

	#itemContainerBottom {
		display: flex;
		flex-direction: column;
		max-height: 175px;
		z-index: 999;
		overflow: hidden;
		background: #fff;
		-webkit-transition: max-height 0.5s ease 0s, color 0.5s ease 0s;
		transition: max-height 0.5s ease 0s, color 0.5s ease 0s;
		box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.5);
		&.collapsed {
			max-height: 0px;
			z-index: 999;
			-webkit-transition: max-height 0.5s ease 0s, color 0.5s ease 0s;
			transition: max-height 0.5s ease 0s, color 0.5s ease 0s;
		}
	}

	.canrank {
		color: #0061a1;
	}

	.canrank:hover {
		color: #000;
	}
}

@media only screen and (max-width: 1024px) {
	.navBar {
		height: 92px;
		li {
			.help {
				height: 30px !important;
			}
		}
		.mobileNavContainer {
			display: flex;
			.itemContainerTop {
				display: flex;
				.filterBtnContainer {
					display: none;
					.filterBtn {
						display: none;
					}
				}
			}
		}
		#navItemsContainer {
			display: none;
		}
	}

	.canolaLink{
		display: none !important;
	}

	.canolaLinkMobile{
		display: flex !important;
		margin: 5px 20px !important;
		max-width: unset !important;
		align-items: center;
	}

	#filterContainer {
		display: flex;
		flex-direction: column;
		height: auto;
		z-index: 9999;
		overflow: hidden;
		background: #fff;
		-webkit-transition: max-height 0.5s ease 0s, color 0.5s ease 0s;
		transition: max-height 0.5s ease 0s, color 0.5s ease 0s;
		box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.5);
		&.collapsed {
			max-height: 0px;
			-webkit-transition: max-height 0.5s ease 0s, color 0.5s ease 0s;
			transition: max-height 0.5s ease 0s, color 0.5s ease 0s;
		}
	}
}

@media only screen and (max-width: 767px) {
	.navBar {
		height: 112px;
		li {
			padding-top: 5px;
			.help {
				margin-top: 0px;
				height: 30px !important;
			}
		}
		.mobileNavContainer {
			display: flex;
			.itemContainerTop {
				display: flex;
				.filterBtnContainer {
					display: none;
					.filterBtn {
						display: none;
					}
				}
				.bars {
					.buttonContainer {
						display: flex;
					}
				}
			}
		}
	}
}

@font-face {
	font-family: 'BASFIconFontNewStyle';
	src: url('iconFont/BASF_Icons.eot?#iefix') format('embedded-opentype'),
		url('iconFont/BASF_Icons.woff') format('woff'),
		url('iconFont/BASF_Icons.ttf') format('truetype'),
		url('iconFont/BASF_Icons.svg#BASFIconFontNewStyle') format('svg');
}
@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-o-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.canolaLink, .canolaLinkMobile{
	margin-top: 5px;
	border: solid 1px;
	max-width: 500px;
	display:flex;
	padding: 5px;
	.canolaText {
		padding-right: 5px;
		align-content: center;
		cursor: pointer;
	} 
	.arrow-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		cursor: pointer;
		background-color: #65ac1e;
		position: relative;
	}

	.arrow-icon:after {
		content: "";
		display: inline-block;
		width: 10px;
		height: 10px;
		border-top: 0.25em solid white;
		border-right: 0.25em solid white;
		transform: rotate(45deg);
		position: absolute;
		margin-left:-3px;
	}
}

.canolaLinkMobile{
	display: none;
}
