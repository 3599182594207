.tooltip {
    position: relative;
    display: inline-block;
    img {
        width: 16px;
    }
}
.tooltip .tooltiptext {
    visibility: hidden;
    background: #ffffff;
    color: #000;
    text-align: center;
    font-size:16px;
    font-weight:400;
    border-radius: 6px;
    padding: 0px 10px;
    box-shadow: 0 8px 6px -6px black;
    /* Position the tooltip */
    position: absolute;
    display: block;
    z-index: 9999;
    right:-100px;
    top:25px;
    white-space: normal;
    width: 200px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}