.imagecomponent-popup {
    z-index:10000;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0, 0.5);
  }	

.imagecomponent-div {
    border-style: solid;
    border-width: 2px;
    z-index: 10005;
    position:absolute;
    background-color: white;
    padding:5px;
    top: calc(50%);
    left: calc(50%);
    transform: translate(-50%, -50%);
    .imagecomponent-block {
        display: block;
        .imagecomponent-close {
            cursor: pointer;
            position: absolute;
            right: 16px;
            top: 8px;
            width: 16px;
            height: 16px;
            opacity: 0.3;
        }
        .imagecomponent-close:hover {
        opacity: 1;
        }
        .imagecomponent-close:before, .imagecomponent-close:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 20px;
        width: 2px;
        background-color: #333;
        }
        .imagecomponent-close:before {
        transform: rotate(45deg);
        }
        .imagecomponent-close:after {
        transform: rotate(-45deg);
        }
        img {
            width: 100%;
            max-height: 750px;
            height: auto;
            display:block;
            margin-left:auto;
            margin-right:auto;
        }
        iframe {
            display:block;
            margin-left:auto;
            margin-right:auto;
        }
        .imagecomponent-slidetext {

            text-align: center;
        }
        .imagecomponent-metadata {
            text-align: center;
            .metadata-component {
                padding-left: 7px;
                padding-right: 7px;
                font-size: 12px; 
                .metadata-SubHeader {
                    font-weight: bold;
                }
                .metadata-Text {
                    font-size: 11px;
                }
            }
        }
    }
  }	

.carousel-pdf-button{
    font-weight: bold;
    height: 35px; 
    width: 35%;
    background: #5b9f05; 
    text-align: center; 
    color: white; 
    font-size: 15px;
    vertical-align: middle;
    padding-top:4px;
}

.imagecomponent-slide-arrowtext-row {
    z-index: 10005;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 15px;
    .imagecomponent-slidetext {
        font-size: 16px;
    }
    .arrow-right {
        width: 0; 
        height: 0; 
        margin-left: 20px;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 15px solid black;
      }
    .arrow-right:hover {
        cursor:pointer;
        border-left: 15px solid grey;
    }
    .arrow-left {
        width: 0; 
        height: 0; 
        margin-right: 20px;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;				
        border-right:15px solid black; 
      }
    .arrow-left:hover {
        cursor:pointer;
        border-right: 15px solid grey;
    }
}