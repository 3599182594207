.chartBarContainer {
    margin:0.75% 0 0.50% 0;
    font-size: 16px;

    .barLabel {
        float: left;
        width: 16%;
        transition: width 500ms ease 0s;
        font-weight: bold;
        line-height: 40px;
    }

    .barContent {
        float: left;
        width: 84%;
        transition: width 500ms ease 0s;

        .bar {
            text-align: center;
            height: 40px;
            line-height: 40px;
            background: #929292;
            color: #fff;
        }
    }
}

@media only screen and (max-width: 1330px) {
    .chartBarContainer {
        .barLabel {
            width: 17%;
        }

        .barContent {
            width: 83%;
        }
    }
}

@media only screen and (max-width: 1153px) {
    .chartBarContainer {
        .barLabel {
            width: 18%;
        }

        .barContent {
            width: 82%;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .chartBarContainer {
        .barLabel {
            width: 20%;
        }

        .barContent {
            width: 80%;
        }
    }
}

@media only screen and (max-width: 896px) {
    .chartBarContainer {
        .barLabel {
            width: 22%;
        }

        .barContent {
            width: 78%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .chartBarContainer {
        margin-bottom: 1.5%;
        font-size: 15.5px;

        .barLabel {
            width: 25%;
            height: 38px;
            line-height: 38px;
        }

        .barContent {
            width: 75%;

            .bar {
                height: 38px;
                line-height: 38px;
            }
        }
    }
}

@media only screen and (max-width: 672px) {
    .chartBarContainer {
        .barLabel {
            width: 30%;
        }

        .barContent {
            width: 70%;
        }
    }
}

@media only screen and (max-width: 576px) {
    .chartBarContainer {
        .barLabel {
            width: 35%;
        }

        .barContent {
            width: 65%;
        }
    }
}

@media only screen and (max-width : 480px) {
    .chartBarContainer {
        margin-bottom: 2%;
        font-size: 15px;

        .barLabel {
            width: 40%;
            height: 36px;
            line-height: 36px;
        }

        .barContent {
            width: 60%;

            .bar {
                height: 36px;
                line-height: 36px;
            }
        }
    }
}

@media only screen and (max-width : 420px) {
    .chartBarContainer {
        .barLabel {
            width: 45%;
        }

        .barContent {
            width: 55%;
        }
    }
}

@media only screen and (max-width : 360px) {
    .chartBarContainer {
        .barLabel {
            width: 50%;
        }

        .barContent {
            width: 50%;
        }
    }
}