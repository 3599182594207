.comparisonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1%;
    margin-bottom: 25px;

    &:last-child {
        margin-bottom: 0px;
    }

    border: 2px solid #777777;

    // box-shadow: 0px 0px 10px 0px #999999;
    .comparisonHeader {
        width: 100%;
        display: flex;
        padding: 14px;
        background: #777777;

        .comparisonTitle {
            align-self: center;
            font-size: 18px;
            line-height: 34px;
            font-weight: bold;
            color: #fff;
        }

        .comparisonDatesContainer {
            display: flex;
            flex: 1;
            justify-content: flex-end;

            .date {
                cursor: pointer;
                width: 85px;
                line-height: 34px;
                transition: 0.3s;
                background: #f2f2f2;
                font-size: 16px;
                font-weight: bold;
                border: 1px solid #777777;
                text-align: center;
            }

            .date.active {
                background: #65ac1e;
                color: #fff;
            }
        }
    }

    .comparisonContent {
        background: #fff;
        .tab-list {
            height: 62px;
            background: none;
            margin-top: 2%;
            padding: 0% 20%;
        }

        .tab-list-item {
            margin: 0% 2%;
            height: 40px;
            align-self: flex-end;
            background: #d3d2d2;
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);

            .tabLabel {
                font-size: 16px;
            }
        }

        .tab-list-active {
            background: #929292;
        }

        .comparisonChartContainer {
            padding: 2% 2% 2% 2%;

            .title {
                font-size: 16px;
                line-height: 24px;
                font-weight: bold;
            }

            .subTitle {
                font-size: 16px;
                line-height: 26px;
                font-weight: bold;
            }

            .chartContainer {
                display: flex;
                flex-direction: column;
                padding-right: 2%;
                padding: 30px 0px 30px 0px;
                background: #fff;
            }
        }

        // @media only screen and (max-width: 1024px) {
        //     .comparisonChartContainer {
        //         padding: 0px 10px !important;
        //     }
        // }

        /* Comparison Action Buttons */
        .trials_list{
            display: flex;
            font-size:18px;
            margin-bottom: 20px;
            .locations{
                font-size:16px;
                -webkit-column-count: 4; /* Chrome, Safari, Opera */
                -moz-column-count: 4; /* Firefox */
                column-count: 4;
                 padding: 10px;    
                 column-gap: 15px;
                 min-width: 100%;
                 margin-top:10px;
                 box-shadow: 0 .125rem .25rem rgba(0,0,0,0.300);
            }
         }

         .m15{
            font-size:16px;
            line-height: 24px;
            font-weight:bold;
         }
         
        .actionButtonsContainer {
            display: flex;
            justify-content: flex-end;
            .actionButtons {
                display: flex;
                flex-direction: row;

                .button {
                    display: flex;
                    /* remove when we want to show these buttons */
                    background-repeat: no-repeat;
                    margin: 4px 10px;
                    cursor: pointer;
                }

                .button:nth-child(1) {
                    width: 35px;
                    height: 40px;
                    background-image: url("../../images/trio.png");
                }

                .button:nth-child(2) {
                    width: 42px;
                    height: 36px;
                    background-image: url("../../images/printer.png");
                }

                .button:nth-child(3) {
                    width: 34px;
                    height: 36px;
                    background-image: url("../../images/pdf.png");
                }
            }

            .seeAllTrialsButton {
                height: 40px;
                color: #fff;
                background-color: #65ac1e;
                font-weight: bold;
                font-size: 16px;
                line-height: 40px;
                padding: 0px 28px;
                margin-left: 20px;
                cursor: pointer;
            }

            .seeAllTrialsButton[disabled] {
                pointer-events: none;
                opacity: 0.7;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .comparisonContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0%;
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0px;
        }

        border: 2px solid #777777;

        // box-shadow: 0px 0px 10px 0px #999999;
        .comparisonHeader {
            width: 100%;
            display: flex;
            padding: 14px;
            background: #777777;
            flex-direction: column;

            .comparisonTitle {
                align-self: center;
                font-size: 18px;
                line-height: 34px;
                font-weight: bold;
                color: #fff;
                flex-direction: row;
                justify-content: center;
            }

            .comparisonDatesContainer {
                display: flex;
                flex: 1;
                justify-content: center;
                flex-direction: row;

                .date {
                    cursor: pointer;
                    width: 85px;
                    line-height: 34px;
                    transition: 0.3s;
                    background: #f2f2f2;
                    font-size: 16px;
                    font-weight: bold;
                    border: 1px solid #777777;
                    text-align: center;
                }

                .date.active {
                    background: #65ac1e;
                    color: #fff;
                }
            }
        }

        .comparisonContent {
            background: #fff;


            .tab-list {
                height: 62px;
                background: none;
                margin-top: 2%;
                padding: 0% 10%;
            }

            .tab-list-item {
                margin: 0% 2%;
                height: 40px;
                align-self: flex-end;
                background: #d3d2d2;
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);

                .tabLabel {
                    font-size: 16px;
                }
            }

            .tab-list-active {
                background: #929292;
            }

            .comparisonChartContainer {
                padding: 2% 2% 2% 2%;

                .title {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: bold;
                }

                .subTitle {
                    font-size: 16px;
                    line-height: 26px;
                }

                .chartContainer {
                    display: flex;
                    flex-direction: column;
                    padding-right: 2%;
                    padding: 20px 5px 0px;
                    background: #fff;
                }
            }

            /* Comparison Action Buttons */
            .trials_list{
                display: flex;
                font-size:18px;
                margin: 0px 15px 15px;
                .locations{
                    font-size:16px;
                    -webkit-column-count: 2; /* Chrome, Safari, Opera */
                    -moz-column-count: 2; /* Firefox */
                    column-count: 2;
                     padding: 10px;    
                     column-gap: 15px;
                     min-width: 100%;
                     margin-top:10px;
                     box-shadow: 0 .125rem .25rem rgba(0,0,0,0.300);
                }
             }

             .m15{
                margin: 0px 15px 15px;  
                font-size:16px;
                line-height: 24px;
                font-weight:bold;
             }
            .actionButtonsContainer {
                display: flex;
                flex-direction: column;
                .column {
                    width: 100%;
                }
                .actionButtons {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;

                    .button {
                        display: flex;
                        /* remove when we want to show these buttons */
                        background-repeat: no-repeat;
                        margin: 4px 10px;
                        cursor: pointer;
                    }

                    .button:nth-child(1) {
                        width: 35px;
                        height: 40px;
                        background-image: url("../../images/trio.png");
                    }

                    .button:nth-child(2) {
                        width: 42px;
                        height: 36px;
                        background-image: url("../../images/printer.png");
                    }

                    .button:nth-child(3) {
                        width: 34px;
                        height: 36px;
                        background-image: url("../../images/pdf.png");
                    }
                }

                .seeAllTrialsButton {
                    display: flex;
                    flex-direction: row;
                    height: 40px;
                    color: #fff;
                    background-color: #65ac1e;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 40px;
                    margin: 10px;
                    cursor: pointer;
                    justify-content: center;
                }

                .seeAllTrialsButton[disabled] {
                    pointer-events: none;
                    opacity: 0.7;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .comparisonContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0%;
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0px;
        }

        border: 2px solid #777777;

        // box-shadow: 0px 0px 10px 0px #999999;
        .comparisonHeader {
            width: 100%;
            display: flex;
            padding: 14px;
            background: #777777;
            flex-direction: column;

            .comparisonTitle {
                align-self: center;
                font-size: 18px;
                line-height: 34px;
                font-weight: bold;
                color: #fff;
                flex-direction: row;
                justify-content: center;
            }

            .comparisonDatesContainer {
                display: flex;
                flex: 1;
                justify-content: center;
                flex-direction: row;

                .date {
                    cursor: pointer;
                    width: 85px;
                    line-height: 34px;
                    transition: 0.3s;
                    background: #f2f2f2;
                    font-size: 16px;
                    font-weight: bold;
                    border: 1px solid #777777;
                    text-align: center;
                }

                .date.active {
                    background: #65ac1e;
                    color: #fff;
                }
            }
        }

        .comparisonContent {
            background: #fff;


            .tab-list {
                height: 62px;
                background: none;
                margin-top: 2%;
                padding: 0% 10%;
            }

            .tab-list-item {
                margin: 0% 2%;
                height: 40px;
                align-self: flex-end;
                background: #d3d2d2;
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);

                .tabLabel {
                    font-size: 16px;
                }
            }

            .tab-list-active {
                background: #929292;
            }

            .comparisonChartContainer {
                padding: 2% 2% 2% 2%;

                .title {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: bold;
                }

                .subTitle {
                    font-size: 16px;
                    line-height: 26px;
                }

                .chartContainer {
                    display: flex;
                    flex-direction: column;
                    padding-right: 2%;
                    padding: 20px 5px 0px;
                    background: #fff;
                }
            }

            /* Comparison Action Buttons */
            .trials_list{
                display: flex;
                font-size:18px;
                margin: 0px 15px 15px;
                .locations{
                    font-size:16px;
                    -webkit-column-count: 1; /* Chrome, Safari, Opera */
                    -moz-column-count: 1; /* Firefox */
                    column-count: 1;
                     padding: 10px;    
                     column-gap: 15px;
                     min-width: 100%;
                     margin-top:10px;
                     box-shadow: 0 .125rem .25rem rgba(0,0,0,0.300);
                }
             }

             .m15{
                margin: 0px 15px 15px;  
                font-size:16px;
                line-height: 24px;
                font-weight:bold;
             }
            .actionButtonsContainer {
                display: flex;
                flex-direction: column;
                .column {
                    width: 100%;
                }
                .actionButtons {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;

                    .button {
                        display: flex;
                        /* remove when we want to show these buttons */
                        background-repeat: no-repeat;
                        margin: 4px 10px;
                        cursor: pointer;
                    }

                    .button:nth-child(1) {
                        width: 35px;
                        height: 40px;
                        background-image: url("../../images/trio.png");
                    }

                    .button:nth-child(2) {
                        width: 42px;
                        height: 36px;
                        background-image: url("../../images/printer.png");
                    }

                    .button:nth-child(3) {
                        width: 34px;
                        height: 36px;
                        background-image: url("../../images/pdf.png");
                    }
                }

                .seeAllTrialsButton {
                    display: flex;
                    flex-direction: row;
                    height: 40px;
                    color: #fff;
                    background-color: #65ac1e;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 40px;
                    margin: 10px;
                    cursor: pointer;
                    justify-content: center;
                }

                .seeAllTrialsButton[disabled] {
                    pointer-events: none;
                    opacity: 0.7;
                }
            }
        }
    }
}