.menuContainer {
    /* TRIAL SUMMARY TOP */
    .trialSummaryHeader {
        display: flex;
        flex-direction: column;
        padding: 20px;
        margin: 0 20px;
        background: #000;
        h2 {
            font-size: 24px;
            color: #fff;
        }
        a {
            font-size: 14px;
            color: #fff; 
        }
    }
    .trialSummaryBody {
        display: flex;
        flex-direction: column;
        padding: 0px 20px 20px 20px;
        .tsBoxTopBorder {
            border-top: 6px solid #0061a1; //169bd8
        }
        .tsBoxTopBorderapt {
            border-top: 6px solid #65ac1e; //169bd8
        }
        .tsBox {
            width: 100%;
            display: flex;
            flex-direction: column;
            background: #fff;
            .tsBoxText{
                padding: 15px 5px 15px 5px;
                font-size: 18px;
            }
            .tsSummaryCont{
                display: flex;
                justify-content: flex-start;
                margin-top: -25px;
            }
            .tsButtonContainer {
                display: flex;
                justify-content: flex-end;
                padding: 10px;
                .tsBoxButton {
                    font-size: 16px;
                    font-weight: bold;
                    text-align: center;
                    color: #fff;
                    background-color: #2e2e2e;
                    cursor: pointer;
                    padding: 6px 15px;
                }
            }
        }
    }

    /* INDIVIDUAL TRIAL SUMMARY TOP */
    .individualTSHeader {
        display: flex;
        justify-content: center;
        // display: flex;
        // flex-direction: column;
        padding: 20px;
        // margin: 0 20px;
        // background: #000;
        //border-bottom: 6px solid #0061a1; //169bd8
        h2 {
            font-size: 24px;
            color: #fff;
        }
        a {
            font-size: 14px;
            color: rgb(0, 0, 0); 
        }

        .individualTSButton {
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            color: #000;
            background-color: #f2f2f2;
            border: #f2f2f2;
            cursor: pointer;
            padding: 10px 15px;
            width: 100%;
            margin-left: 10px;
        }
    }
    .trialList {
    }
    .hybridContainer {
        position: relative;
        width: 100%;
        height: 300px;
        background-image: url('../../../images/3125_CTA.jpg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        .image-overlay {
            position: absolute;
            top: 50%;
            left: 43%;
            transform: translate(-50%, -50%);
            color: #fff; 
            width: 80%; 
        }
        .hybridText{
            font-size: 16px;
            margin-bottom: 15px;
            width: 75%;
        }
        button{
            height: 30px;
            font-size: 15px;
            font-weight: bold;
            color: #fff;
            background: #5b9f05;
            border: none;
            cursor: pointer;
        }
    }
    @media only screen and (max-width: 1024px){
        .hybridContainer {
        background-size: cover;
        }
    }
    @media only screen and (max-width: 450px){
        .hybridContainer {
            height: 18vh;
            background-size: cover;
        .hybridText{
            font-size: 12px;
        }
        button{
            height: 25px;
            font-size: 12px;
        }
        }
    }
    @media only screen and (max-width: 320px){
        .hybridContainer {
            height: 18vh;
            background-size: cover;
        .hybridText{
            font-size: 12px;
            margin-bottom: 8px;
            width: 100%;
        }
    }
    }   

}

.locationDetailsContainer {
    background-color: #e2eefe;
    padding: 10px;
    margin: 10px 20px;
    border: 1px solid #1f60a2;
}

.locationDetails {
    font-size: 14px;
    color: #004A96;
}

.tab-list-item.tab-list-active {
    background-color: #000 !important;
    padding: 15px !important;
}