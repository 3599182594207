
.chartTabsNavBar {
    display: flex;
    width: 100%;
    background-color: #bbbaba;
    .chartNavContainer{
        margin-left:15px;
    }
        .chartTabsNavItem {
            background: #fff;
            padding: 8px 16px;
            font-size: 16px;
            margin: 15px 7px;
            cursor: pointer;
            width: 12%;
            text-align: center;
        }
}
@media only screen and (max-width: 1024px) { 
    .chartTabsNavBar {
        .trialNavContainer{
        }
            .chartTabsNavItem {
                background: #fff;
                padding: 8px 16px;
                font-size: 16px;
                margin: 15px 7px;
                cursor: pointer;
                width: 20%;
                text-align: center;
            }
    }
}
@media only screen and (max-width: 767px) { 
    .chartTabsNavBar {
        display: flex;
        width: 100%;
        background-color: #bbbaba;
        justify-content: center;
        .chartNavContainer{
         margin-left:0px;
        }
            .chartTabsNavItem {
                background:#fff;  
                padding: 6px 5px;
                font-size: 16px;
                margin: 15px 7px;
                cursor: pointer;
                width: 30%;
                text-align: center;
            }
    }
}

.chartTabsContainer {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #6f6e6e;
    padding: 20px 0px 0px;
    color: #000;
    .chartTabsNavBar {
        display: flex;
        width: 100%;
        background: #fff;
        .chartTabsNavItem {
            padding: 10px 20px;
            font-size: 16px;
            font-weight: bold;
            margin: 0px 20px 0px 0px;
            cursor: pointer;
            width: 20%;
            border: 1px solid #6f6e6e;
            border-bottom: 0;
        }
        .sliderButton {
            display: none;
        }
        .desktopChartButton {
            display: block;
        }
        .active{
            background: #6f6e6e;
            color:#fff;
        }
    }
}
@media only screen and (max-width:1024px) { 
    .chartTabsContainer {
        display: flex;
        flex-direction: column;
        border-bottom:1px solid #6f6e6e;
        padding: 20px 0px 0px;
        color: #000;
        .chartTabsNavBar {
            display: flex;
            width: 100%;
            background: #fff;
            .chartTabsNavItem {
                padding: 10px 20px;
                font-size: 16px;
                font-weight: bold;
                margin: 0px 20px 0px 0px;
                cursor: pointer;
                border: 1px solid #6f6e6e;
                border-bottom: 0;
                width: 23%;
            }
            .sliderButton {
                display: none;
            }
            .desktopChartButton {
                display: block;
            }
            .active{
                background: #6f6e6e;
                color:#fff;
            }
        }
}
}
@media only screen and (max-width:767px) { 
    .chartTabsContainer {
        .chartTabsNavBar {
            .chartTabsNavItem {
                padding: 8px 16px;
                font-size: 14px;
                font-weight: bold;
                margin: 0px 15px;
                text-align:center;
                width: 45%;
                height: 40px;
                line-height: 22px;
            }
            .sliderButton {
                display: block;
                margin-left: 10px;
                margin-right: 10px;
            }
            .desktopChartButton {
                display: none;
            }
            img {
                margin-top: 10px;
                height: 25px;
                width: 20px;
            }
        }
    }
}

@media only screen and (max-width:360px) { 
    .chartTabsContainer {
        .chartTabsNavBar {
            .chartTabsNavItem {
                padding: 8px 16px;
                font-size: 14px;
                font-weight: bold;
                margin: 0px 15px;
                text-align:center;
                width: 52%;
                height: 40px;
                line-height: 22px;
            }
        }
    }
}

.noTabDivider {
    border-bottom: none;
}